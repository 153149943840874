import * as adwords from './adwords';
import * as googleAnalytics from './googleAnalytics';
import * as yoTrack from './yotrack';

function getTracker(aftType) {
  if (aftType == null || aftType === 'google') {
    return googleAnalytics;
  } else if (aftType === 'adwords') {
    return adwords;
  } else if (aftType === 'yotrack') {
    return yoTrack;
  }
  return null;
}

export function trackAffiliatePage(aftId, aftType) {
  if (!aftId) {
    return;
  }
  const tracker = getTracker(aftType);
  if (tracker) {
    tracker.trackPage(aftId);
  }
}

export function trackAffiliateEvent(aftId, aftType, event, properties) {
  if (!aftId || !event) {
    return;
  }
  const tracker = getTracker(aftType);
  if (tracker) {
    tracker.trackEvent(aftId, event, properties);
  }
}
