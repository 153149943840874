/* eslint-disable */

import $ from 'jquery';
import 'jquery.plugin';

// -----------------------------------------------------------------------------
// Data API
// -----------------------------------------------------------------------------

$(window).on('googlemaps.load', () => $('[data-geolocator]').geolocator());

// -----------------------------------------------------------------------------
// geolocator Plugin API
// -----------------------------------------------------------------------------

$.plugin('geolocator', {
  init() {
    this.geolocation =
      typeof navigator !== 'undefined' && navigator !== null ? navigator.geolocation : undefined;
    this.maps = typeof google !== 'undefined' && google !== null ? google.maps : undefined;
    this.supported = this.geolocation != null && this.maps != null;
    if (this.supported) {
      this.geocoder = new this.maps.Geocoder();
      return (this.field = this.element.closest('.field-input, .lm-crux-field-input'));
    }
  },

  run() {
    this.element.on('focus.geolocator', event => this.onFocus(event));
    this.element.on('keyup.geolocator', event => this.onKeyUp(event));
  },

  onFocus(event) {
    if (this.supported && this.element.val() === '') {
      this._startLoad();
      return this.geolocation.getCurrentPosition(
        position => this.onPosition(position),
        error => this._cancelLoad()
      );
    }
  },

  onKeyUp(event) {
    if (this.element.val() !== '') {
      this._cancelLoad();
      return this.element.off('.geolocator');
    }
  },

  onPosition(position) {
    if (position.coords != null) {
      const latLng = new this.maps.LatLng(position.coords.latitude, position.coords.longitude);
      return this.geocoder.geocode({ latLng }, (results, status) => {
        return this.onGeocode(results, status);
      });
    } else {
      return this._cancelLoad();
    }
  },

  onGeocode(results, status) {
    if (status === this.maps.GeocoderStatus.OK && results[0] && this.element.val() === '') {
      this.element.val(results[0].formatted_address);
      this.field.addClass('geolocated-field-input');
    }
    return this._cancelLoad();
  },

  _startLoad() {
    return this.field.toggleClass('is-loading', true);
  },

  _cancelLoad() {
    return this.field.toggleClass('is-loading', false);
  },
});
