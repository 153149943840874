import $ from 'jquery';
import React from 'react';
import InsuranceIssuerChoiceField from '../containers/InsuranceIssuerChoiceFieldContainer';

const REACT_CLASSES = {
  InsuranceIssuerChoiceField,
};

export default function GenericRenderer(element) {
  const $element = $(element);
  const className = $element.data('react-class');
  const reactClass = REACT_CLASSES[className];
  if (reactClass == null) {
    throw new Error(`React component could not be found: ${className}`);
  }
  const props = $element.data('react-props');
  return React.createElement(reactClass, props);
}
