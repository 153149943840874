import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import OverlayView from 'react-google-maps/lib/OverlayView';

export const SMALL = 1;
export const MEDIUM = 2;
export const LARGE = 3;
const SIZE_CLASSNAMES = {
  1: 'cluster--small',
  2: 'cluster--medium',
  3: 'cluster--large',
};

export default class LocationMapCluster extends Component {
  static SMALL = SMALL;
  static MEDIUM = MEDIUM;
  static LARGE = LARGE;

  static propTypes = {
    position: PropTypes.object,
    size: PropTypes.oneOf([SMALL, MEDIUM, LARGE]),
    label: PropTypes.node,
    href: PropTypes.string,
    zIndex: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.onMarkerInteraction = this.onMarkerInteraction.bind(this);
  }

  onMarkerInteraction() {
    this.forceUpdate();
  }

  getPixelPositionOffset(width, height) {
    return { x: -(width / 2), y: -(height / 2) };
  }

  renderMarker() {
    const { href, label, size, zIndex } = this.props;
    const className = classNames('cluster', SIZE_CLASSNAMES[size]);
    const style = { zIndex };
    if (href) {
      return (
        <a
          href={href}
          className={className}
          onMouseOver={this.onMarkerInteraction}
          onMouseOut={this.onMarkerInteraction}
          style={style}
        >
          {label}
        </a>
      );
    }
    return (
      <div className={className} style={style}>
        {label}
      </div>
    );
  }

  render() {
    return (
      <OverlayView
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        position={this.props.position}
        getPixelPositionOffset={this.getPixelPositionOffset}
      >
        {this.renderMarker()}
      </OverlayView>
    );
  }
}
