/* global window */

import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';

import GlobalInsuranceIssuerSelect from '../components/forms/insurance-issuer-select/GlobalInsuranceIssuerSelect';

function normalizeInputValue(inputValue) {
  return inputValue ? inputValue.toLowerCase() : '';
}

const addSelectedIssuerData = graphql(
  gql`
    query GlobalInsuranceIssuerSelectSelectedIssuerData($id: ID!) {
      insuranceIssuer(id: $id) {
        id
        display
        state
      }
    }
  `,
  {
    name: 'selectedIssuerData',
    options({ insuranceIssuerId }) {
      return { skip: insuranceIssuerId == null, variables: { id: insuranceIssuerId } };
    },
  }
);

const addFeaturedData = graphql(
  gql`
    query GlobalInsuranceIssuerSelectFeaturedData($countryCode: String!, $perPage: Int!) {
      insuranceIssuers(featured: true, countryCode: $countryCode, perPage: $perPage) {
        nodes {
          id
          display
          state
        }
      }
    }
  `,
  {
    name: 'featuredData',
    options({ isOpen, countryCode }) {
      return {
        skip: !isOpen,
        variables: {
          countryCode,
          perPage: window.CLIENT_ENV.TOP_INSURANCES_COUNT || 10,
        },
      };
    },
  }
);

const addAutocompleteData = graphql(
  gql`
    query GlobalInsuranceIssuerSelectAutocompleteData(
      $autocomplete: String!
      $countryCode: String!
    ) {
      insuranceIssuers(autocomplete: $autocomplete, countryCode: $countryCode) {
        nodes {
          id
          display
          state
        }
      }
    }
  `,
  {
    name: 'autocompleteData',
    options({ inputValue, selectedItem, toLabel, countryCode }) {
      inputValue = normalizeInputValue(inputValue);
      const skip =
        inputValue.length < 2 || normalizeInputValue(toLabel(selectedItem)) === inputValue;
      return { skip, variables: { autocomplete: inputValue, countryCode } };
    },
  }
);

export default compose(addSelectedIssuerData, addFeaturedData, addAutocompleteData)(
  GlobalInsuranceIssuerSelect
);
