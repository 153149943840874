/* global window */

import $ from 'jquery';
import React from 'react';
import './initializers/RefluxInitializer';
import ApplicationInitializer from './initializers/ApplicationInitializer';

// For React dev tools
window.React = React;

$(() => ApplicationInitializer.start('#app'));
