import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import InsuranceIssuerSelectField from '../components/forms/InsuranceIssuerSelectField';

const InsuranceIssuerSelectFieldGraphQLContainer = ({ data, ...props }) => (
  <InsuranceIssuerSelectField coverage={data && data.providerInsuranceCoverage} {...props} />
);

InsuranceIssuerSelectFieldGraphQLContainer.displayName =
  'InsuranceIssuerSelectFieldGraphQLContainer';

InsuranceIssuerSelectFieldGraphQLContainer.propTypes = {
  data: PropTypes.shape({
    providerInsuranceCoverage: PropTypes.object,
  }),
};

export default graphql(
  gql`
    query InsuranceIssuerSelectField($providerId: ID!, $insuranceIssuerId: ID!) {
      providerInsuranceCoverage(providerId: $providerId, insuranceIssuerId: $insuranceIssuerId) {
        status
        message
      }
    }
  `,
  {
    options({ providerId, insuranceIssuerId }) {
      return {
        skip: providerId == null,
        variables: {
          providerId,
          insuranceIssuerId: insuranceIssuerId == null ? '' : insuranceIssuerId,
        },
      };
    },
  }
)(InsuranceIssuerSelectFieldGraphQLContainer);
