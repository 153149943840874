import React from 'react';
import createReactClass from 'create-react-class';
import Reflux from 'reflux';
import LocationMap from '../components/LocationMap';
import ApplicationStore from '../stores/ApplicationStore';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  mixins: [Reflux.ListenerMixin],

  getInitialState() {
    return {
      googleMapsApi: ApplicationStore.data.googleMapsApi,
    };
  },

  componentDidMount() {
    this.listenTo(ApplicationStore, this.onApplicationStoreChange);
  },

  onApplicationStoreChange(payload) {
    this.setState({ googleMapsApi: payload.googleMapsApi });
  },

  render() {
    return <LocationMap googleMapsApi={this.state.googleMapsApi} {...this.props} />;
  },
});
