/* eslint-disable */

import $ from 'jquery';
import relocate from 'relocate';
import 'jquery.get-target';
import 'jquery.plugin';

// -----------------------------------------------------------------------------
// relocatable Plugin API
// -----------------------------------------------------------------------------

$(() => $('[data-relocatable]').relocatable());

// -----------------------------------------------------------------------------
// relocatable Plugin API
// -----------------------------------------------------------------------------

// Relocates an element to a target placeholder based on the
// current window width using `relocate`.
//
//   <div id="content-placeholder"></div>
//   <div data-relocatable data-target="#content-placeholder" data-relocatable-minwidth="480">
//     This content will move to `#content-placeholder` when the window is 480px.
//   </div>
$.plugin('relocatable', {
  defaults: {
    minWidth: 800,
  },

  dataOptionMapping: {
    minWidth: 'data-relocatable-minwidth',
  },

  init() {
    this.target = this.element.getTarget();
    if (this.element.length && (this.target != null ? this.target.length : undefined)) {
      return relocate(this.options.minWidth, this.element[0], this.target[0]);
    }
  },
});
