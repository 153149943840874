/* eslint-disable */

import $ from 'jquery';
import 'jquery.plugin';
import { trackEvent } from './tracking';

// -----------------------------------------------------------------------------
// Data API
// -----------------------------------------------------------------------------

$(document).on('submit.ajax-form.data-api', '[data-ajax-form]', function(event) {
  event.preventDefault();
  return $(this).submitAjaxForm();
});

// -----------------------------------------------------------------------------
// submitAjaxForm Plugin API
// -----------------------------------------------------------------------------

$.plugin('submitAjaxForm', {
  defaults: {
    submittingClass: 'is-submitting',
    successMessage: 'Thanks! Your form has been successfully submitted.',
    errorMessage: 'Sorry, the form could not be submitted.',
  },

  dataOptionMapping: {
    successMessage: 'data-ajax-form-success-message',
    errorMessage: 'data-ajax-form-error-message',
    eventName: 'data-ajax-form-event-name',
  },

  run() {
    this.clearMessages(this.element);
    this.element.toggleClass(this.options.submittingClass, true);

    const url = this.element.attr('action');
    const data = this.element.serialize();
    const method = this.element.attr('method');

    return $.ajax(url, {
      data,
      dataType: 'json',
      type: method,
    })
      .done((data, status, xhr) => this.onSubmitSuccess(data, status, xhr))
      .fail((xhr, status, error) => this.onSubmitError(xhr, status, error))
      .always(() => this.element.toggleClass(this.options.submittingClass, false));
  },

  onSubmitSuccess(data, status, xhr) {
    this.element.empty();
    this.element.append(this.createSuccessMessage(this.options.successMessage));
    if (this.options.eventName != null) {
      trackEvent(this.options.eventName, this.element.data('ajax-form-event-properties'))
    }
  },

  onSubmitError(xhr, status, error) {
    let errors;
    if (xhr.responseJSON != null) {
      ({ errors } = xhr.responseJSON);
    } else {
      errors = {};
    }
    this.element.prepend(this.createErrorMessage(this.options.errorMessage));
    return (() => {
      const result = [];
      for (let fieldName in errors) {
        const fieldErrors = errors[fieldName];
        const inputWrapper = this.element.find(`[name='${fieldName}']`).closest('.field-input');
        if (inputWrapper.length) {
          this.clearMessages(inputWrapper);
          result.push(inputWrapper.append(this.createErrorMessage(fieldErrors[0])));
        } else {
          result.push(undefined);
        }
      }
      return result;
    })();
  },

  clearMessages(element) {
    return element.find('.success-message, .error-message').remove();
  },

  createSuccessMessage(message) {
    return $('<div>', { class: 'success-message', text: message });
  },

  createErrorMessage(message) {
    return $('<div>', { class: 'error-message', text: message });
  },
});
