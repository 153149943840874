/* eslint-disable */

import $ from 'jquery';
import 'jquery.flexslider';
import 'jquery.magnific-popup';
import 'jquery.plugin';
import api from '../api';

// -----------------------------------------------------------------------------
// Data API
// -----------------------------------------------------------------------------

$(() => $('[data-office-gallery]').officeGallery());

// -----------------------------------------------------------------------------
// officeGallery Plugin API
// -----------------------------------------------------------------------------

$.plugin('officeGallery', {
  dataOptionMapping: {
    url: 'data-office-gallery',
  },

  init() {
    this.slides = this.element.find('.slides');
    return (this.loaded = false);
  },

  run() {
    if (!this.loaded) {
      return api
        .get(this.options.url)
        .then(response => {
          return this.onGalleryLoad(response.data);
        })
        .catch(() => {
          return (this.loaded = true);
        });
    }
  },

  onGalleryLoad(data) {
    const slides = data.photos.map(photo => this._buildImage(photo));
    this.slides.html(slides);
    this.element.flexslider({
      animation: 'slide',
      animationLoop: false,
      itemWidth: 100,
      itemMargin: 6,
      slideshow: false,
      controlNav: false,
      keyboard: false,
    });
    this.slides.magnificPopup({
      delegate: 'a',
      type: 'image',
      mainClass: 'mfp-with-zoom',
      gallery: {
        enabled: true,
      },
      zoom: {
        enabled: true,
        duration: 300,
        easing: 'ease-in-out',
      },
    });
    return (this.loaded = true);
  },

  _buildImage(photo) {
    if (__guard__(photo != null ? photo.size : undefined, x => x.thumb) != null) {
      return `\
<li>
  <a href="${photo.size.large}">
    <img src="${photo.size.thumb}" alt="">
  </a>
</li>\
`;
    }
  },
});

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined;
}
