import React from 'react';
import createIcon from './createIcon';

const LoadC = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <title>Load</title>
    <path d="M479.8,226.1c-1.4-8.7-3.1-17.3-5.3-25.8c-2.8-10.8-6.4-21.5-10.8-31.8c-8.9-21.2-21.1-41-35.9-58.6 c-16-18.9-35.3-35.2-56.7-47.7C350,49.8,327,41,303,36.1c-12.4-2.5-24.9-4-37.6-4.1c-9.9-0.1-19.8,0.3-29.6,1.2 c-25.5,2.5-50.7,9.6-73.9,20.5c-19.9,9.4-38.4,21.6-54.8,36.2c-16.4,14.6-30.7,31.6-42.2,50.3c-12.7,20.8-22.2,43.5-27.4,67.3 c-4.2,19-6.2,38.6-5.2,58.1c0.9,18.9,3.8,37.8,9.5,55.9c3.6,11.5,7.9,22.7,13.3,33.6c5.3,10.7,11.5,21,18.4,30.7 c13.8,19.3,30.6,36.4,49.8,50.5c19.6,14.5,41.7,25.7,64.9,33.1c24.2,7.7,49.9,11.3,75.3,10.4c24.8-0.8,49.4-5.6,72.6-14.5 c22.3-8.6,43.2-20.9,61.5-36.3c9.2-7.8,17.4-16.6,25.1-25.9c7.8-9.4,14.8-19.3,20.6-30c5-9.2,9.2-18.8,12.8-28.5 c1.8-4.8,3.5-9.6,4.9-14.6c1.5-5.3,2.6-10.8,3.6-16.2c1.5-8.5,2.1-17.3,1.3-25.9c-0.7,3.8-1.3,7.5-2.2,11.2 c-1.1,4.3-2.5,8.5-4.1,12.6c-3.2,8.7-7.2,17.1-11,25.5c-4.9,10.7-10.6,20.9-16.8,30.8c-3.2,5.1-6.5,10.1-10.1,14.9 c-3.6,4.8-7.7,9.4-11.8,13.9c-8.2,9.1-17.1,17.2-27,24.4c-10.1,7.4-20.8,13.9-32.1,19.3c-22.6,11-47.3,17.6-72.3,19.8 c-25.6,2.2-51.7-0.3-76.3-7.6c-23.4-6.9-45.6-18.1-65.1-32.8c-18.9-14.3-35.3-31.9-48.2-51.8C75.4,347.8,66.1,324.9,61,301 c-1.3-6.2-2.3-12.6-3-18.9c-0.6-5.4-1.1-10.9-1.3-16.4c-0.3-11.3,0.4-23,2.1-34.2c3.7-24.6,11.7-48.3,24.1-69.9 c11-19.3,25.3-36.7,42.1-51.4c16.8-14.8,36-26.7,56.8-35.1c12-4.9,24.6-8.5,37.4-10.9c6.5-1.2,13-2.2,19.5-2.7 c3.2-0.3,6.3-0.3,9.5-0.6c1.3,0,2.6,0.1,3.9,0.1c21.7-0.4,43.5,2.4,64.2,8.9c22.3,7,43.3,18.3,61.5,33c19.1,15.4,35,34.4,47,55.8 c10.2,18.2,17.5,37.8,21.9,58.2c1,4.7,1.8,9.4,2.6,14.1c0.7,4.3,3.1,8.3,6.8,10.7c7.8,5.2,18.7,1.7,22.5-6.7 C479.9,232.1,480.3,229,479.8,226.1z" />
  </svg>
);

LoadC.displayName = 'LoadC';

export default createIcon(LoadC);
