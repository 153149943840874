import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Field from '../base/Field';
import RadioSet from '../base/RadioSet';
import { NEW, RETURNING } from '../../constants';

export default class PatientStatusRadio extends Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    wrapperClassName: PropTypes.string,
    textOptionOne: PropTypes.string,
    textOptionTwo: PropTypes.string,
    disabled: PropTypes.bool,
    patientStatusId: PropTypes.string,
    onChange: PropTypes.func,
    isOnWidget: PropTypes.bool,
  };

  static defaultProps = {
    label: null,
    wrapperClassName: null,
    name: 'patient-status',
    textOptionOne: 'New Patient',
    textOptionTwo: 'Returning Patient',
  };

  constructor(props) {
    super(props);
    this.onStatus = this.onStatus.bind(this);
  }

  onStatus(e) {
    const patientStatusId = e.target.value;
    const { onChange } = this.props;
    if (onChange) {
      onChange(patientStatusId);
    }
  }

  isReturning() {
    return this.props.patientStatusId === RETURNING;
  }

  renderSegmentedToggles(id) {
    const { label, wrapperClassName, isOnWidget, name } = this.props;
    if (isOnWidget) {
      return this.renderRadios(id);
    }
    return (
      <Field name={name} label={label} wrapperClassName={wrapperClassName}>
        {this.renderRadios(id)}
      </Field>
    );
  }

  renderRadios() {
    const { textOptionOne, textOptionTwo, isOnWidget, name } = this.props;
    const label = isOnWidget ? this.props.label : null;
    let { id } = this.props;
    id = id || name;
    return (
      <RadioSet
        group={id}
        label={label}
        radios={[
          {
            value: NEW,
            checked: !this.isReturning(),
            changeEvent: this.onStatus,
            text: textOptionOne,
          },
          {
            value: RETURNING,
            checked: this.isReturning(),
            changeEvent: this.onStatus,
            text: textOptionTwo,
          },
        ]}
      />
    );
  }

  renderReturningPatientsOnly() {
    const { name } = this.props;
    let { id } = this.props;
    id = id || name;

    if (this.props.isOnWidget) {
      return (
        <RadioSet
          group={id}
          label="This office only schedules existing patients at this time."
          radios={[
            {
              value: RETURNING,
              checked: true,
              text: 'Accepting Returning Patients Only',
            },
          ]}
        />
      );
    }
    return (
      <div className="field-label">
        <label htmlFor={id} className="field-label__patient-acceptance">
          <strong>
            {'This office only schedules existing patients through online scheduling at this time.'}
          </strong>
        </label>
      </div>
    );
  }

  renderNewPatientsOnly() {
    const { name } = this.props;
    let { id } = this.props;
    id = id || name;

    if (this.props.isOnWidget) {
      return (
        <RadioSet
          group={id}
          label="This office is currently accepting new patients only."
          radios={[
            {
              value: NEW,
              checked: true,
              text: 'Accepting New Patients Only',
            },
          ]}
        />
      );
    }
    return (
      <div className="field-label">
        <label htmlFor={id} className="field-label__patient-acceptance">
          <strong>
            {'This office only schedules new patients through online scheduling at this time.'}
          </strong>
        </label>
      </div>
    );
  }

  render() {
    const { id, disabled } = this.props;

    if (disabled) {
      if (this.isReturning()) {
        return this.renderReturningPatientsOnly();
      }
      return this.renderNewPatientsOnly();
    }
    return this.renderSegmentedToggles(id);
  }
}
