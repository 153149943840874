import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { partnerDataShape } from '../utils/withPartnerData';
import withWidgetSettings from '../utils/withWidgetSettings';

class PartnerDataProviderGraphQLContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    partnerData: partnerDataShape,
  };

  static childContextTypes = {
    partnerData: partnerDataShape,
  };

  getChildContext() {
    return { partnerData: this.props.partnerData };
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

const addPartnerData = graphql(
  gql`
    query PartnerDataProvider($partnerId: ID!) {
      partner(id: $partnerId) {
        logo
        widgetDisclaimer
        insuranceIssuers(perPage: 500) {
          nodes {
            isFeatured
            insuranceIssuer {
              id
              display
              state
            }
          }
        }
      }
    }
  `,
  {
    name: 'partnerData',
    options({ widgetSettings: { partnerId } }) {
      return { skip: !partnerId, variables: { partnerId } };
    },
  }
);

export default compose(withWidgetSettings(), addPartnerData)(PartnerDataProviderGraphQLContainer);
