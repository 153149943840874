import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Combobox from '../../base/Combobox';
import SuggestedInsuranceIssuersMenu from './SuggestedInsuranceIssuersMenu';
import InsuranceIssuerSelectOption from './InsuranceIssuerSelectOption';

const insuranceIssuersDataShape = PropTypes.shape({
  loading: PropTypes.bool.isRequired,
  insuranceIssuers: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        display: PropTypes.string.isRequired,
        state: PropTypes.string,
      })
    ).isRequired,
  }),
});

export default class GlobalInsuranceIssuerSelect extends Component {
  static propTypes = {
    insuranceIssuer: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    insuranceIssuerId: PropTypes.string,
    emptyLabel: PropTypes.string,
    selectedIssuerData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      insuranceIssuer: PropTypes.shape({
        id: PropTypes.string.isRequired,
        display: PropTypes.string.isRequired,
        state: PropTypes.string,
      }),
    }),
    autocompleteData: insuranceIssuersDataShape,
    featuredData: insuranceIssuersDataShape,
  };

  renderMenu = comboboxProps => {
    const { autocompleteData, featuredData } = this.props;
    if (autocompleteData) {
      return this.renderSearchResults(comboboxProps);
    } else if (featuredData) {
      return this.renderFeaturedIssuers(comboboxProps);
    }
    return null;
  };

  renderFeaturedIssuers(comboboxProps) {
    const { featuredData: { loading, insuranceIssuers }, emptyLabel } = this.props;
    if (loading || insuranceIssuers.nodes.length === 0) {
      return null;
    }
    return (
      <SuggestedInsuranceIssuersMenu
        emptyLabel={emptyLabel}
        featuredInsuranceIssuers={insuranceIssuers.nodes}
        comboboxProps={comboboxProps}
        showSearchLabel
      />
    );
  }

  renderSearchResults(comboboxProps) {
    const { autocompleteData: { loading, insuranceIssuers } } = this.props;
    if (loading) {
      return null;
    }
    return insuranceIssuers.nodes.map((issuer, index) => (
      <InsuranceIssuerSelectOption key={issuer.id} item={issuer} index={index} {...comboboxProps} />
    ));
  }

  render() {
    const {
      selectedIssuerData,
      autocompleteData,
      featuredData,
      insuranceIssuer,
      insuranceIssuerId,
    } = this.props;

    const loading =
      (selectedIssuerData && selectedIssuerData.loading) ||
      (autocompleteData && autocompleteData.loading) ||
      (featuredData && featuredData.loading);

    let selectedItem = insuranceIssuer;
    if (
      selectedItem == null &&
      selectedIssuerData &&
      selectedIssuerData.insuranceIssuer &&
      selectedIssuerData.insuranceIssuer.id === insuranceIssuerId
    ) {
      selectedItem = selectedIssuerData.insuranceIssuer;
    }

    return (
      <Combobox loading={loading} selectedItem={selectedItem} {...this.props}>
        {this.renderMenu}
      </Combobox>
    );
  }
}
