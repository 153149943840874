import 'core-js/fn/object/assign';
import moment from 'moment-timezone';
import sortBy from 'lodash.sortby';
import { isBlank } from './stringUtils';
import { NEW, RETURNING } from '../constants';

function getSelectedDate(data) {
  let { selectedDate } = data;
  if (isBlank(selectedDate)) {
    selectedDate = moment();
  }
  return { selectedDate };
}

function getMinDate(data) {
  let { minDate } = data;
  if (isBlank(minDate)) {
    minDate = moment();
  } else {
    minDate = moment(minDate);
  }
  return { minDate };
}

export function getReasonForVisitParams(data, { autoSelect = false } = {}) {
  const { defaultReasonForVisit } = data;
  let { patientStatusId, reasonForVisitId, reasonsForVisit } = data;

  if (reasonsForVisit == null) {
    reasonsForVisit = [];
  }

  if (patientStatusId !== RETURNING) {
    patientStatusId = NEW;
  }

  let selectedReasonForVisit = reasonsForVisit.find(reason => reason.id === reasonForVisitId);

  if (autoSelect && !selectedReasonForVisit) {
    if (patientStatusId === NEW && defaultReasonForVisit) {
      selectedReasonForVisit = defaultReasonForVisit;
    } else {
      selectedReasonForVisit = sortBy(reasonsForVisit, reason => reason.display).find(
        reason => reason.patientStatus.id === patientStatusId
      );
    }
  }

  if (selectedReasonForVisit) {
    reasonForVisitId = selectedReasonForVisit.id;
    if (selectedReasonForVisit.patientStatus) {
      patientStatusId = selectedReasonForVisit.patientStatus.id;
    }
  }

  return { patientStatusId, reasonForVisitId, selectedReasonForVisit };
}

export function getProviderDetailParams(providerDetail, options) {
  const {
    patientStatusId,
    reasonForVisitId,
    provider: { defaultReasonForVisit, reasonsForVisit } = {},
  } = providerDetail;
  return Object.assign(
    {},
    getSelectedDate(providerDetail),
    getMinDate(providerDetail),
    getReasonForVisitParams(
      { patientStatusId, reasonForVisitId, defaultReasonForVisit, reasonsForVisit },
      options
    ),
    { insuranceIssuerId: providerDetail.insuranceIssuerId }
  );
}

export function getOfficeDetailParams(officeDetail) {
  return Object.assign(
    { specialtyId: officeDetail.specialtyId },
    getReasonForVisitParams(officeDetail)
  );
}
