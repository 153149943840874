import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import OpeningsCalendar from 'localmed-openings-calendar';
import ProviderPageCalendarHeaderGraphQLContainer from '../graphql/ProviderPageCalendarHeaderGraphQLContainer';
import ProviderCalendarTableGraphQLContainer from '../graphql/ProviderCalendarTableGraphQLContainer';
import withParams from '../utils/withParams';
import ProviderPageCalendarForm from './provider-page-calendar/ProviderPageCalendarForm';

class ProviderPageCalendar extends Component {
  static propTypes = {
    provider: PropTypes.object.isRequired,
    calendarType: PropTypes.string,
    reasonsForVisit: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        display: PropTypes.string.isRequired,
      })
    ),
    insuranceIssuers: PropTypes.array,
    params: PropTypes.object.isRequired,
    changeParams: PropTypes.func.isRequired,
  };

  static defaultProps = {
    calendarType: OpeningsCalendar.AUTO_DETECT_WEEKEND,
    reasonsForVisit: [],
  };

  constructor(props) {
    super(props);
    this.state = { showDatepicker: false };
  }

  onToggleDatepicker = () =>
    this.setState(({ showDatepicker }) => ({ showDatepicker: !showDatepicker }));

  onChangePatientStatusId = patientStatusId => {
    this.props.changeParams({ patientStatusId });
    this.setState(() => ({ showDatepicker: false }));
  };

  onChangeReasonForVisitId = reasonForVisitId => {
    this.props.changeParams({ reasonForVisitId });
    this.setState(() => ({ showDatepicker: false }));
  };

  onChangeSelectedDate = selectedDate => {
    this.props.changeParams({ selectedDate });
    this.setState(() => ({ showDatepicker: false }));
  };

  onGotoPreviousCalendar = () => {
    const { selectedDate } = this.props.params;
    const previousWeekDate = moment(selectedDate).add({ days: -7 });
    this.onChangeSelectedDate(previousWeekDate);
  };

  onGotoNextCalendar = () => {
    const { selectedDate } = this.props.params;
    const nextWeekDate = moment(selectedDate).add({ days: 7 });
    this.onChangeSelectedDate(nextWeekDate);
  };

  render() {
    const { params } = this.props;
    const endDate = moment(params.selectedDate).add({ days: 6 });
    return (
      <div className="provider-calendar provider-calendar--boxed">
        <ProviderPageCalendarHeaderGraphQLContainer
          provider={this.props.provider}
          params={this.props.params}
          startDate={params.selectedDate}
          minDate={params.minDate}
          endDate={endDate}
          showDatepicker={this.state.showDatepicker}
          onRequestToggleDatepicker={this.onToggleDatepicker}
          onRequestChangeSelectedDate={this.onChangeSelectedDate}
        />
        <ProviderPageCalendarForm
          patientStatusId={params.patientStatusId}
          reasonForVisitId={params.reasonForVisitId}
          reasonsForVisit={this.props.reasonsForVisit}
          onRequestChangePatientStatus={this.onChangePatientStatusId}
          onRequestChangeReasonForVisit={this.onChangeReasonForVisitId}
        />
        <ProviderCalendarTableGraphQLContainer
          provider={this.props.provider}
          calendarType={this.props.calendarType}
          patientStatusId={params.patientStatusId}
          reasonForVisitId={params.reasonForVisitId}
          insuranceIssuerId={params.insuranceIssuerId}
          selectedDate={params.selectedDate}
          minDate={params.minDate}
          onRequestPreviousCalendar={this.onGotoPreviousCalendar}
          onRequestNextCalendar={this.onGotoNextCalendar}
          onRequestNextAvailableCalendar={this.onChangeSelectedDate}
          isOnWidget={false}
          isSchedulingInWidget={false}
        />
      </div>
    );
  }
}

export default withParams()(ProviderPageCalendar);
