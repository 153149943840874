import Reflux from 'reflux';

export default Reflux.createActions({
  changePatientStatus: {},
  changeReasonForVisit: {},
  changeSelectedDate: {},
  toggleDatepicker: {},
  gotoPrevious: {},
  gotoNext: {},
});
