/* eslint-disable */

import $ from 'jquery';

$(function() {
  const toggleFieldSet = function(inputSelector, fieldsetSelector, checkFunction) {
    const checkFieldset = () => $(fieldsetSelector).toggleClass('is-active', checkFunction());
    if ($(inputSelector).length) {
      $(document).on('change', inputSelector, checkFieldset);
      checkFieldset();
    }
  };

  return toggleFieldSet(
    'input[name="different_contact"]',
    '.contact-fieldset',
    () => !!$('input[name="different_contact"]:checked').length
  );
});
