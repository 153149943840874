/* eslint-disable */

import $ from 'jquery';
import { pauseClickForTracking, trackEvent } from './tracking';

// -----------------------------------------------------------------------------
// Data API
// -----------------------------------------------------------------------------

$(document).on('click.trackLink', '[data-track-link]', function(event) {
  const element = $(this);
  const eventName = element.data('track-link');
  const eventProperties = element.data('track-link-properties');
  if (eventName != null) {
    pauseClickForTracking(event);
    return trackEvent(eventName, eventProperties);
  }
});
