import React from 'react';
import PatientStatusRadio from '../components/forms/PatientStatusRadio';
import ReasonForVisitSelect from '../components/forms/ReasonForVisitSelect';
import { NEW } from '../constants';

export default {
  filterReasonsForVisit(reasonsForVisit = [], patientStatusId = null) {
    const reasons = [];
    let hasOtherPatientStatus = false;

    reasonsForVisit.forEach(reason => {
      if (patientStatusId == null || reason.patientStatus.id === patientStatusId) {
        reasons.push(reason);
      } else {
        hasOtherPatientStatus = true;
      }
    });

    return [reasons, hasOtherPatientStatus];
  },

  renderReasonForVisitCombo({
    patientStatusId,
    reasonForVisitId,
    reasonsForVisit,
    patientStatusLabel,
    wrapperClassName,
    includeNewPatientExam = false,
  }) {
    const [filteredReasonsForVisit, hasOtherPatientStatus] = this.filterReasonsForVisit(
      reasonsForVisit,
      patientStatusId
    );

    return [
      <PatientStatusRadio
        key="patientStatusRadio"
        patientStatusId={patientStatusId}
        label={patientStatusLabel}
        wrapperClassName={wrapperClassName}
        disabled={!hasOtherPatientStatus}
        onChange={this.onPatientStatusIdChange}
      />,
      <ReasonForVisitSelect
        key="reasonForVisitSelect"
        reasonForVisitId={reasonForVisitId}
        reasonsForVisit={filteredReasonsForVisit}
        includeNewPatientExam={includeNewPatientExam && patientStatusId === NEW}
        onChange={this.onReasonForVisitIdChange}
      />,
    ];
  },
};
