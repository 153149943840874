import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ComboboxOption = ({
  children,
  item,
  index,
  highlightedIndex,
  selectedItem,
  getItemProps,
  toLabel,
  toValue,
}) => {
  const className = classNames('combobox__option', {
    'combobox__option--highlighted': highlightedIndex === index,
    'combobox__option--selected': selectedItem != null && toValue(selectedItem) === toValue(item),
  });
  return (
    <div {...getItemProps({ item })} className={className}>
      {children || toLabel(item)}
    </div>
  );
};

ComboboxOption.displayName = 'ComboboxOption';

ComboboxOption.propTypes = {
  children: PropTypes.node,
  item: PropTypes.any,
  index: PropTypes.number,
  highlightedIndex: PropTypes.number,
  selectedItem: PropTypes.any,
  getItemProps: PropTypes.func.isRequired,
  toLabel: PropTypes.func.isRequired,
  toValue: PropTypes.func.isRequired,
};

export default ComboboxOption;
