import React from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import ProviderPageCalendarHeader from '../components/provider-page-calendar/ProviderPageCalendarHeader';
import { convertOpening } from '../utils/openingUtils';
import toISODateString from '../utils/toISODateString';

const ProviderPageCalendarHeaderGraphQLContainer = ({
  data: { loading, nextOpening } = {},
  ...props
}) => {
  let firstOpening = null;
  if (!loading && nextOpening) {
    const { provider, params } = props;
    firstOpening = convertOpening(nextOpening, {
      ...params,
      providerUri: provider.uri,
      timeZone: provider.timeZone,
    });
  }
  return <ProviderPageCalendarHeader firstOpening={firstOpening} {...props} />;
};

ProviderPageCalendarHeaderGraphQLContainer.displayName =
  'ProviderPageCalendarHeaderGraphQLContainer';

ProviderPageCalendarHeaderGraphQLContainer.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool,
    nextOpening: PropTypes.object,
  }),
  provider: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    timeZone: PropTypes.string.isRequired,
  }).isRequired,
  params: PropTypes.object.isRequired,
};

export default compose(
  graphql(
    gql`
      query ProviderPageCalendarHeader(
        $providerId: ID!
        $reasonForVisitId: ID!
        $minDate: String!
      ) {
        nextOpening(providerId: $providerId, reasonForVisitId: $reasonForVisitId, after: $minDate) {
          id
          time
        }
      }
    `,
    {
      options({ provider, params: { reasonForVisitId, minDate } }) {
        return {
          skip: reasonForVisitId == null,
          variables: {
            reasonForVisitId,
            providerId: provider.id,
            minDate: toISODateString(minDate),
          },
        };
      },
    },
    ``
  )
)(ProviderPageCalendarHeaderGraphQLContainer);
