export default function toISODateString(datetime) {
  if (datetime == null) {
    return null;
  }
  if (typeof datetime === 'string') {
    const sinceEpoch = Date.parse(datetime);
    if (isNaN(sinceEpoch)) {
      return null;
    }
    // eslint-disable-next-line no-param-reassign
    datetime = new Date(sinceEpoch);
  }
  return datetime.toISOString().slice(0, 10);
}
