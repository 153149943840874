/* global console */

// We're using Polyfill.io for most of our polyfills, but there's a bug related to IE10 that
// requires us to use core-js's version of setPrototypeOf.
// See: https://github.com/Financial-Times/polyfill-service/issues/1238
import 'core-js/fn/object/set-prototype-of';

import $ from 'jquery';
import { cookie } from 'browser-cookie-lite';
import tracker from 'localmed-tracker';
import 'localmed-core';
import 'localmed-datepicker';
import 'localmed-password-checker';
import 'localmed-reveal';
import 'localmed-typeahead';
import 'localmed-typeahead/lib/initial';
import './main/ajaxForm';
import './main/autotabs';
import './main/expandHeight';
import './main/filterbox';
import './main/formQueryParamsLinks';
import './main/geolocator';
import './main/marketingHeader';
import './main/modals';
import './main/notice';
import './main/officeGallery';
import './main/relocatable';
import './main/reveal';
import './main/searchForms';
import './main/toggleFieldsets';
import './main/trackEventsFromServer';
import './main/trackForm';
import './main/trackLink';
import './main/truncate';
import './main/mobileBrowserCheck';
import './flux/index';

$.ajaxSetup({
  beforeSend(xhr, settings) {
    // Only send the token to relative URLs i.e. locally.
    if (!/^https?:.*/.test(settings.url)) {
      xhr.setRequestHeader('X-CSRFToken', cookie('csrftoken'));
    }
  },
});

$(() => {
  // eslint-disable-line prefer-arrow-callback
  try {
    tracker.init({ overrideAttribution: true });
  } catch (error) {
    if (console) {
      console.error(`tracker.init() failed with error: ${error}`); // eslint-disable-line no-console
    }
  }

  // enables buttons on initial load. fixes issue when button is disabled after
  // user searches, and hits back after viewing their search results
  $('[data-disable-with]').button('enable');
});
