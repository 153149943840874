/* global document */

const _cache = {};

export default function loadJavascript(url) {
  if (!_cache[url]) {
    _cache[url] = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      script.src = url;
      document.head.appendChild(script);
    });
  }
  return _cache[url];
}
