import React from 'react';
import $ from 'jquery';
import LocationMapContainer from '../containers/LocationMapContainer';

function extractElementClassName(element) {
  const className = element.attr('class');
  element.attr('class', '');
  return className;
}

function getFrame(element) {
  const centerTargetSelector = element.data('target');
  if (centerTargetSelector) {
    return $(centerTargetSelector).get(0);
  }
  return null;
}

export default function LocationMapRenderer(element) {
  const $element = $(element);
  return (
    <LocationMapContainer
      className={extractElementClassName($element)}
      bounds={$element.data('location-map-bounds')}
      frame={getFrame($element)}
      markers={$element.data('location-map-markers') || []}
      clusters={$element.data('location-map-clusters') || []}
    />
  );
}
