import React from 'react';
import PropTypes from 'prop-types';

import Field from '../base/Field';
import Select from '../base/Select';

const SelectField = ({ id, name, label, ...props }) => {
  id = id || name;
  return (
    <Field id={id} name={name} label={label}>
      <Select {...props} id={id} name={name} />
    </Field>
  );
};

SelectField.displayName = 'SelectField';

SelectField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default SelectField;
