/* eslint-disable */

import $ from 'jquery';
import 'jquery.reveal';

$(() =>
  $(document).on('click', '[data-reveal-id]', function(event) {
    event.preventDefault();
    const element = $(this);
    const revealID = element.attr('data-reveal-id');
    return $(`#${revealID}`).reveal(element.data());
  })
);
