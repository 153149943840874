import { getGoogleTag } from '../gtag';

export async function trackPage(aftId) {
  const gtag = getGoogleTag(aftId);
  gtag('event', 'page_view');
}

export async function trackEvent(aftId, event, properties) {
  if (event === 'Scheduled Appointment') {
    event = 'Scheduled LocalMed Appointment';
  }
  const googleProperties = {
    eventCategory: 'LocalMed Appointment',
    eventLabel: null,
  };
  if (
    properties &&
    (event === 'Scheduled LocalMed Appointment' || event === 'Viewed LocalMed Appointment Form')
  ) {
    googleProperties.eventLabel = properties.providerId;
  }
  const gtag = getGoogleTag(aftId);
  gtag('event', event, googleProperties);
}
