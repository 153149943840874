import React from 'react';
import PropTypes from 'prop-types';
import CSSTransitionGroup from 'localmed-react-utils/lib/CSSTransitionGroup';

const ScaleupTransition = ({ children, ...props }) => (
  <CSSTransitionGroup
    {...props}
    transitionName="scaleup"
    transitionEnterTimeout={300}
    transitionLeaveTimeout={300}
  >
    {children}
  </CSSTransitionGroup>
);

ScaleupTransition.propTypes = {
  children: PropTypes.node,
};

export default ScaleupTransition;
