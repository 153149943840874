import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { DATE_TIME_FORMAT } from '../constants';
import formatCalendarTime from '../utils/formatCalendarTime';
import getTimeZoneFormat from '../utils/getTimeZoneFormat';

const CalendarTime = ({ intl, datetime, timeZone }) => (
  <time
    dateTime={datetime.toISOString()}
    title={intl.formatTime(datetime, {
      timeZone: getTimeZoneFormat(timeZone),
      ...DATE_TIME_FORMAT.FULL,
    })}
  >
    {formatCalendarTime(intl, datetime, { timeZone })}
  </time>
);

CalendarTime.displayName = 'CalendarTime';
CalendarTime.propTypes = {
  intl: intlShape.isRequired,
  datetime: PropTypes.shape({
    toISOString: PropTypes.func.isRequired,
  }).isRequired,
  timeZone: PropTypes.string,
};

export default injectIntl(CalendarTime);
