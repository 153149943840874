import moment from 'moment-timezone';
import supportsTimeZoneFormat from './supportsTimeZoneFormat';
import { buildOpeningUrl, widgetizeUrl } from './urlUtils';

// eslint-disable-next-line import/prefer-default-export
export function convertOpening(opening, options = {}) {
  if (opening == null) {
    return null;
  }
  let uri = opening.uri;
  if (options.providerUri && opening.id) {
    uri = buildOpeningUrl(options.providerUri, opening.id, {
      insuranceIssuerId: options.insuranceIssuerId,
      patientStatusId: options.patientStatusId,
      reasonForVisitId: options.reasonForVisitId,
      minDate: options.minDate,
    });
  }
  if (options.widgetize) {
    uri = widgetizeUrl(uri);
  }
  const convertedOpening = { ...opening, uri };
  if (supportsTimeZoneFormat()) {
    convertedOpening.time = moment.tz(opening.time, options.timeZone);
    convertedOpening.timeZone = options.timeZone;
  } else {
    convertedOpening.time = moment(opening.time);
  }
  return convertedOpening;
}
