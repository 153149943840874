/* eslint-disable */

import $ from 'jquery';
import 'jquery.typeahead';
import 'jquery.typeahead-initial';

$(function() {
  $('.search-form').each(function() {
    const $form = $(this);
    return $form.on('change typeahead:autocompleted typeahead:selected', ':input', function(event) {
      if ($(event.target).data('search-form') === false) {
        return;
      }
      if ($form.data('location-search-form') != null) {
        // If only the insurance changes on a location page, stay on the location page.
        // Otherwise we want to go to the provider search page.
        if (event.target.name === 'insurance_issuer_id') {
          $form.attr('action', '');
        }
      }
      return $form.submit();
    });
  });

  const specialtyTemplate = context =>
    (context.fields != null ? context.fields.specialistName : undefined) != null
      ? context.fields != null ? context.fields.specialistName : undefined
      : context.value;

  const remoteFilter = response => (response != null ? response.suggestions : undefined);

  const specialtiesSearch = $('.specialties-search-input');
  if (specialtiesSearch.length) {
    specialtiesSearch.typeahead([
      {
        name: 'specialties',
        template: specialtyTemplate,
        header: '<h3 class="tt-header">Specialties</h3>',
        remote: {
          url: '/suggestions/specialties/?q=%QUERY',
          filter: remoteFilter,
        },
      },
      {
        name: 'providers',
        header: '<h3 class="tt-header">Providers</h3>',
        remote: {
          url: '/suggestions/providers/?q=%QUERY',
          filter: remoteFilter,
        },
      },
    ]);

    if (window.topSpecialties) {
      specialtiesSearch.typeaheadInitial({
        name: 'topSpecialties',
        local: window.topSpecialties,
        template: specialtyTemplate,
        header: '<h3 class="tt-header">Top Specialties</h3>',
      });
    }
  }

  const issuersSearch = $('.issuers-search-input');
  if (issuersSearch.length) {
    issuersSearch.typeahead({
      name: 'issuers',
      remote: {
        url: '/suggestions/insurance/?q=%QUERY',
        filter: remoteFilter,
      },
    });

    if (window.topIssuers) {
      return issuersSearch.typeaheadInitial({
        name: 'topIssuers',
        local: window.topIssuers,
        header: '<h3 class="tt-header">Top Insurance</h3>',
      });
    }
  }
});
