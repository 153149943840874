import React from 'react';
import PropTypes from 'prop-types';

const ComboboxSectionTitle = ({ children, ...props }) => (
  <div className="combobox__section-title" {...props}>
    {children}
  </div>
);

ComboboxSectionTitle.displayName = 'ComboboxSectionTitle';

ComboboxSectionTitle.propTypes = {
  children: PropTypes.node,
};

export default ComboboxSectionTitle;
