/* eslint-disable */

import $ from 'jquery';
import { pauseSubmitForTracking, trackEvent } from './tracking';

// -----------------------------------------------------------------------------
// Data API
// -----------------------------------------------------------------------------

$(document).on('submit.trackForm', '[data-track-form]', function(event) {
  const element = $(this);
  const eventName = element.data('track-form');
  const eventProperties = element.data('track-form-properties');
  if (eventName != null) {
    pauseSubmitForTracking(this, event);
    trackEvent(eventName, eventProperties)
  }
});
