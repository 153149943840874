/* eslint-disable */

import $ from 'jquery';
import throttle from './throttle';
import 'jquery.get-target';
import 'jquery.plugin';
import 'jquery.trunk8';

// -----------------------------------------------------------------------------
// Data API
// -----------------------------------------------------------------------------

$(function() {
  $('[data-toggle="truncate"]').toggleTruncate();
  return $(document).on('click.toggleTruncate.data-api', '[data-toggle="truncate"]', function(
    event
  ) {
    event.preventDefault();
    return $(this).toggleTruncate();
  });
});

// -----------------------------------------------------------------------------
// truncate Plugin API
// -----------------------------------------------------------------------------

$.plugin('truncate', {
  defaults: {
    lines: 3,
    tooltip: false,
  },

  dataOptionMapping: {
    fill: 'data-truncate-fill',
    lines: 'data-truncate-lines',
    maxLines: 'data-truncate-max-lines',
    side: 'data-truncate-side',
    tooltip: 'data-truncate-tooltip',
    width: 'data-truncate-width',
  },

  init() {
    this.expanded = true;
    // Throttle the window resize event to every 50ms
    const resizeTimeout = null;
    return $(window).on('resize', throttle(event => this._onResize(event)));
  },

  run() {
    this.expanded = false;
    if (this.options.maxLines != null) {
      this.element.trunk8($.extend({}, this.options, { lines: this.options.maxLines }));
      if (this.isVisible()) {
        return this.element.trigger('truncate:visible');
      }
    }
    this.element.trunk8(this.options);
    if (this.isVisible()) {
      return this.element.trigger('truncate:visible');
    } else {
      return this.element.trigger('truncate:collapsed');
    }
  },

  expand() {
    if (this.expanded) {
      return;
    }
    this.element.trunk8('revert');
    this.expanded = true;
    return this.element.trigger('truncate:expanded');
  },

  collapse() {
    if (!this.expanded) {
      return;
    }
    return this.run();
  },

  toggle() {
    if (this.expanded) {
      return this.collapse();
    } else {
      return this.expand();
    }
  },

  isVisible() {
    return this.element.html() === this.element.data('trunk8').original_text;
  },

  _onResize(event) {
    if (!this.expanded) {
      return this.run();
    }
  },
});

// -----------------------------------------------------------------------------
// toggleTruncate Plugin API
// -----------------------------------------------------------------------------

$.plugin('toggleTruncate', {
  defaults: {
    expandedHTML: '- Read Less',
  },

  dataOptionMapping: {
    expandedHTML: 'data-toggle-truncate-expanded-html',
  },

  init() {
    this.target = this.element.getTarget();
    this.truncate = this.target.getOrCreatePlugin('truncate');
    this.options.collapsedHTML = this.element.html();
    if (this.options.expandedHTML === 'false') {
      this.options.expandedHTML = false;
    }
    this.target.on('truncate:collapsed', event => this._onTruncateCollapsed(event));
    this.target.on('truncate:expanded', event => this._onTruncateExpanded(event));
    return this.target.on('truncate:visible', event => this._onTruncateVisible(event));
  },

  run() {
    return this.truncate.toggle();
  },

  _onTruncateCollapsed(event) {
    return this.element.show().html(this.options.collapsedHTML);
  },

  _onTruncateExpanded(event) {
    if (this.options.expandedHTML) {
      return this.element.show().html(this.options.expandedHTML);
    } else {
      return this.element.hide();
    }
  },

  _onTruncateVisible(event) {
    return this.element.hide();
  },
});
