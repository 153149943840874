/* eslint-disable */

import $ from 'jquery';
import 'jquery.get-target';
import 'jquery.plugin';
import 'jquery.reveal';

// -----------------------------------------------------------------------------
// Data API
// -----------------------------------------------------------------------------

$(function() {
  $(document).on('click', '[data-close-reveal-modal]', function(event) {
    event.preventDefault();
    return $('.reveal-modal.open').trigger('reveal:close');
  });

  return $(document).on('click.deleteModal.data-api', '[data-toggle="delete-modal"]', function(
    event
  ) {
    event.preventDefault();
    return $(this).deleteModal();
  });
});

// -----------------------------------------------------------------------------
// deleteModal Plugin API
// -----------------------------------------------------------------------------

// "deleteModal" automatically pops up a modal that contains a form for
// DELETEing a resource. The modal itself needs to already exist on the page.
// The "action" attribute automatically gets changed to the "href" of the
// link that triggers the modal. Here's an example:
//
//     <!-- Link to trigger modal -->
//     <a href="/providers/123/" data-toggle="delete-modal" data-target="#delete">
//       Delete Provider
//     </a>
//     <!-- Actual modal -->
//     <div id="delete" class="reveal-modal">
//       <div class="reveal-modal-inner">
//         <p>Are you sure?</p>
//         <form method="POST" class="form">
//           <button>Yes</button>
//         </form>
//         <a class="close-reveal-modal"><i class="icon-close-sign"></i><span>Close</span></a>
//       </div>
//     </div>
$.plugin('deleteModal', {
  defaults: {
    url: null,
    formSelector: 'form',
  },

  dataOptionMapping: {
    url: 'data-delete-modal-url',
  },

  init() {
    this.target = this.element.getTarget();
    this.form = this.target.find(this.options.formSelector);
    return (this.options.url = this.element.attr('href'));
  },

  run() {
    this.target.reveal();
    return this.form.attr('action', this.options.url);
  },
});
