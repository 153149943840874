import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Select from '../components/base/Select';

export default class GenericSelectContainer extends Component {
  static propTypes = {
    defaultItemId: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      itemId: props.defaultItemId,
    };
  }

  onChange = itemId => {
    this.setState({ itemId });
  };

  render() {
    return <Select {...this.props} itemId={this.state.itemId} onChange={this.onChange} />;
  }
}
