/* global window, newrelic */

import { getGoogleTag } from "./gtag";

const PAUSE_TIMEOUT = 300;

// Pulled from Segment.io's analytics.js:
// https://github.com/segmentio/is-meta
function isMeta(event) {
  if (event.metaKey || event.altKey || event.ctrlKey || event.shiftKey) {
    return true;
  }
  const { which, button } = event;
  if (!which && button !== undefined) {
    // eslint-disable-next-line no-bitwise, no-extra-parens
    return !button & 1 && !button & 2 && button & 4;
  } else if (which === 2) {
    return true;
  }
  return false;
}

export function pauseForTracking(callback) {
  return setTimeout(callback, PAUSE_TIMEOUT);
}

export function pauseClickForTracking(event) {
  const { href, target } = event.currentTarget;
  if (href && target !== '_blank' && !isMeta(event)) {
    event.preventDefault();
    const targetWindow = target === '_parent' || target === '_top' ? window.top : window;
    return pauseForTracking(() => {
      targetWindow.location.href = href;
    });
  }
  return null;
}

/**
 * @param {HTMLFormElement} form
 * @param {SubmitEvent} event
 */
export function pauseSubmitForTracking(form, event) {
  event.preventDefault();
  pauseForTracking(() => {
    form.submit();
  });
}

function getLocalMedGoogleTag() {
  if (
    window.CLIENT_ENV == null ||
    window.CLIENT_ENV.GOOGLE_TAG_MANAGER_ENABLED === false ||
    window.CLIENT_ENV.GOOGLE_TAG_ID == null
  ) {
    return () => {};
  }
  return getGoogleTag(window.CLIENT_ENV.GOOGLE_TAG_ID);
}

/**
 * @param {string} event - The name of the event
 * @param {Record<string, any>} [properties] - the event properties
 */
export function trackEvent(event, properties) {
  if (event === 'Scheduled Appointment') {
    // eslint-disable-next-line no-param-reassign
    delete properties.contactMobilePhone;
  }
  const gtag = getLocalMedGoogleTag();
  gtag('event', event, properties);
  if (typeof newrelic !== 'undefined') {
    newrelic.addPageAction(event, properties);
  }
}

getLocalMedGoogleTag();
