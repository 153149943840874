import React from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash.sortby';
import SelectField from './SelectField';

const ReasonForVisitSelect = ({ reasonsForVisit, reasonForVisitId, ...selectFieldProps }) => {
  reasonsForVisit = sortBy(reasonsForVisit, reason => reason.display);
  return (
    <SelectField
      {...selectFieldProps}
      items={reasonsForVisit}
      itemId={reasonForVisitId}
      disableSearch
      openUntilSelected
    />
  );
};

ReasonForVisitSelect.displayName = 'ReasonForVisitSelect';

ReasonForVisitSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  reasonsForVisit: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    })
  ).isRequired,
  reasonForVisitId: PropTypes.string,
};

ReasonForVisitSelect.defaultProps = {
  label: 'What can we help you with?',
  name: 'reason-for-visit',
  placeholder: 'Please select…',
};

export default ReasonForVisitSelect;
