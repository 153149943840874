import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PRIMARY = 'primary';
export const SUCCESS = 'success';
export const WARNING = 'warning';
export const ERROR = 'error';
export const SMALL = 'small';

export default class Message extends Component {
  static PRIMARY = PRIMARY;
  static SUCCESS = SUCCESS;
  static WARNING = WARNING;
  static ERROR = ERROR;
  static SMALL = SMALL;

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    type: PropTypes.oneOf([PRIMARY, SUCCESS, WARNING, ERROR]),
    size: PropTypes.oneOf([SMALL]),
    isInverse: PropTypes.bool,
  };

  static defaultProps = {
    type: PRIMARY,
    size: null,
    isInverse: false,
  };

  renderClassName() {
    const { className, type, size, isInverse } = this.props;
    return classNames(className, {
      [`${type}-message`]: true,
      [`${size}-message`]: size,
      'inverse-message': isInverse,
    });
  }

  render() {
    return <div className={this.renderClassName()}>{this.props.children}</div>;
  }
}
