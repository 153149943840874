import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import ReasonForVisitComboMixin from '../../mixins/ReasonForVisitComboMixin';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  propTypes: {
    patientStatusId: PropTypes.string,
    reasonForVisitId: PropTypes.string,
    reasonsForVisit: PropTypes.arrayOf(
      PropTypes.shape({
        patientStatus: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      })
    ),
    onRequestChangePatientStatus: PropTypes.func.isRequired,
    onRequestChangeReasonForVisit: PropTypes.func.isRequired,
  },

  mixins: [ReasonForVisitComboMixin],

  onPatientStatusIdChange(patientStatusId) {
    this.props.onRequestChangePatientStatus(patientStatusId);
  },

  onReasonForVisitIdChange(reasonForVisitId) {
    this.props.onRequestChangeReasonForVisit(reasonForVisitId);
  },

  render() {
    const { patientStatusId, reasonForVisitId, reasonsForVisit } = this.props;
    const fields = this.renderReasonForVisitCombo({
      patientStatusId,
      reasonForVisitId,
      reasonsForVisit,
    });
    return (
      <div className="provider-calendar__form">
        <form className="form gray-form filter-form right-icons-form">{fields}</form>
      </div>
    );
  },
});
