import React from 'react';
import PropTypes from 'prop-types';
import difference from 'lodash.difference';

import { CASH_PAYMENT_ID, OTHER_ID } from '../../../constants';
import ComboboxMessage from '../../base/combobox/ComboboxMessage';
import ComboboxSectionTitle from '../../base/combobox/ComboboxSectionTitle';
import InsuranceIssuerSelectOption from './InsuranceIssuerSelectOption';

function extractCashPayment(issuers) {
  const cashPayment = issuers.find(issuer => issuer.id === CASH_PAYMENT_ID);
  if (cashPayment) {
    issuers = difference(issuers, [cashPayment]);
  }
  return [cashPayment, issuers];
}

const SuggestedInsuranceIssuersMenu = ({
  featuredInsuranceIssuers,
  featuredInsuranceIssuersLabel,
  allInsuranceIssuers,
  allInsuranceIssuersLabel,
  emptyLabel,
  searchLabel,
  showSearchLabel,
  comboboxProps,
}) => {
  const results = [];

  let index = 0;
  const addOption = issuer => {
    results.push(
      <InsuranceIssuerSelectOption key={issuer.id} item={issuer} index={index} {...comboboxProps} />
    );
    index += 1;
  };

  const [cashPayment, featuredInsuranceIssuersWithoutCashPayment] = extractCashPayment(
    featuredInsuranceIssuers
  );
  if (cashPayment) {
    addOption(cashPayment);
  }
  if (emptyLabel) {
    addOption({ id: OTHER_ID, display: emptyLabel });
  }

  if (featuredInsuranceIssuersWithoutCashPayment.length > 0) {
    results.push(
      <ComboboxSectionTitle key="FEATURED_INSURANCE_ISSUERS_LABEL">
        {featuredInsuranceIssuersLabel}
      </ComboboxSectionTitle>
    );
    featuredInsuranceIssuersWithoutCashPayment.forEach(addOption);
  }

  if (allInsuranceIssuers && allInsuranceIssuers.length > 0) {
    results.push(
      <ComboboxSectionTitle key="ALL_INSURANCE_ISSUERS_LABEL">
        {allInsuranceIssuersLabel}
      </ComboboxSectionTitle>
    );
    allInsuranceIssuers.forEach(addOption);
  } else if (showSearchLabel) {
    results.push(
      <ComboboxSectionTitle key="ALL_INSURANCE_ISSUERS_LABEL">
        {allInsuranceIssuersLabel}
      </ComboboxSectionTitle>,
      <ComboboxMessage key="SEARCH_LABEL">{searchLabel}</ComboboxMessage>
    );
  }
  if (results.length === 0) {
    return null;
  }
  return <div>{results}</div>;
};

SuggestedInsuranceIssuersMenu.displayName = 'SuggestedInsuranceIssuersMenu';

SuggestedInsuranceIssuersMenu.propTypes = {
  featuredInsuranceIssuers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  allInsuranceIssuers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  emptyLabel: PropTypes.string,
  searchLabel: PropTypes.string,
  featuredInsuranceIssuersLabel: PropTypes.string,
  allInsuranceIssuersLabel: PropTypes.string,
  showSearchLabel: PropTypes.bool,
  comboboxProps: PropTypes.object.isRequired,
};

SuggestedInsuranceIssuersMenu.defaultProps = {
  featuredInsuranceIssuersLabel: 'Top Insurances',
  allInsuranceIssuersLabel: 'All Insurances',
  searchLabel: 'Type to search all insurances…',
};

export default SuggestedInsuranceIssuersMenu;
