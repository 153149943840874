import React from 'react';
import PropTypes from 'prop-types';
import LocationMapContainer from '../containers/LocationMapContainer';
import { buildProviderSchedulingUrl } from '../utils/urlUtils';
import withParams from '../utils/withParams';

const ProviderMap = ({ adjacentProviders, params }) => {
  const markers = adjacentProviders.map(provider => ({
    ...provider,
    uri: buildProviderSchedulingUrl(provider.id, params),
  }));
  return <LocationMapContainer className="profile__map map" markers={markers} />;
};

ProviderMap.propTypes = {
  adjacentProviders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  params: PropTypes.object,
};

export default withParams()(ProviderMap);
