/* global document */

import urlParse from 'url-parse';
import toISODateString from './toISODateString';

function urlJoin(...segments) {
  return segments.reduce((left, right) => `${left.replace(/\/$/, '')}/${right.replace(/^\//, '')}`);
}

function removeWidgetPath(pathname) {
  return pathname.replace(/\/(widget\/?)?\s*$/, '');
}

function addWidgetPath(pathname) {
  return urlJoin(removeWidgetPath(pathname), '/widget/');
}

function filterParams(params) {
  /* eslint-disable no-param-reassign */
  return Object.keys(params).reduce((acc, key) => {
    const value = params[key];
    if (value !== undefined) {
      acc[key] = value == null ? '' : value;
    }
    return acc;
  }, {});
}

export function changeUrl(url) {
  document.location.href = url.toString();
}

export function widgetizeUrl(url) {
  url = urlParse(url);
  url.set('pathname', addWidgetPath(url.pathname));
  return url.toString();
}

export function buildPracticeUrl(practiceUrl, params = {}) {
  const url = urlParse(practiceUrl);
  url.set(
    'query',
    filterParams({
      insuranceIssuerId: params.insuranceIssuerId,
      specialtyId: params.specialtyId,
      patientStatusId: params.patientStatusId,
      reasonForVisitId: params.reasonForVisitId,
    })
  );
  return url.toString();
}

export function buildPracticeSchedulingUrl(practiceId, params = {}) {
  return buildPracticeUrl(`/practices/${practiceId}/widget/`, params);
}

export function buildOfficeUrl(officeUrl, params = {}) {
  const url = urlParse(officeUrl);
  url.set(
    'query',
    filterParams({
      insuranceIssuerId: params.insuranceIssuerId,
      specialtyId: params.specialtyId,
      patientStatusId: params.patientStatusId,
      reasonForVisitId: params.reasonForVisitId,
    })
  );
  return url.toString();
}

export function buildOfficeSchedulingUrl(officeId, params = {}) {
  return buildOfficeUrl(`/offices/${officeId}/widget/`, params);
}

export function buildProviderUrl(providerUrl, params = {}) {
  const url = urlParse(providerUrl);
  url.set(
    'query',
    filterParams({
      insuranceIssuerId: params.insuranceIssuerId,
      patientStatusId: params.patientStatusId,
      reasonForVisitId: params.reasonForVisitId,
      minDate: toISODateString(params.minDate),
      after: params.after,
    })
  );
  return url.toString();
}

export function buildProviderSchedulingUrl(providerId, { widget = false, ...params } = {}) {
  const url = widget ? `/providers/${providerId}/widget/` : `/providers/${providerId}/`;
  return buildProviderUrl(url, params);
}

export function buildOpeningUrl(providerUrl, openingId, params = {}) {
  const match = openingId.match(/(\d{8}T\d{6}Z)[_-](\w{7})$/);
  const openingSlug = `${match[1]}-${match[2]}`;
  const url = urlParse(providerUrl);
  url.set('pathname', urlJoin(removeWidgetPath(url.pathname), `/openings/${openingSlug}/`));
  url.set(
    'query',
    filterParams({
      insurance_issuer_id: params.insuranceIssuerId,
      patient_status_id: params.patientStatusId,
      reason_for_visit_id: params.reasonForVisitId,
      minDate: toISODateString(params.minDate),
    })
  );
  return url.toString();
}

function getReferrer() {
  return document.referrer;
}

function getLocation() {
  return document.location;
}

export function getBackUrl() {
  const referrer = getReferrer();
  if (referrer === '') {
    return null;
  }
  const href = getLocation().href;
  if (urlParse(referrer).hostname === urlParse(href).hostname) {
    return referrer;
  }
  return null;
}
