import React from 'react';
import PropTypes from 'prop-types';
import { Target } from 'react-popper';

import ArrowDownB from '../icons/ArrowDownB';
import LoadC from '../icons/LoadC';

const ComboboxTrigger = props => (
  <Target>
    <div className="combobox__trigger">
      {props.disableSearch ? (
        <button
          {...props.getButtonProps()}
          id={props.id}
          className="combobox__button"
          disabled={props.disabled}
          ref={props.focusableRef}
        >
          {props.selectedItem ? (
            props.renderSelected(props)
          ) : (
            <span className="combobox__placeholder">{props.placeholder}</span>
          )}
        </button>
      ) : (
        <input
          {...props.getInputProps()}
          id={props.id}
          className="combobox__input"
          type="text"
          placeholder={props.placeholder}
          disabled={props.disabled}
          onClick={props.toggleMenu}
          ref={props.focusableRef}
          autoCorrect="off"
          spellCheck="off"
          /* Changes to this input should not trigger search form submissions. This should be
           removed once the search form is rebuilt with React */
          data-search-form="false"
        />
      )}

      {props.loading ? (
        <div className="combobox__indicator">
          <LoadC spin />
        </div>
      ) : (
        <button
          {...props.getButtonProps()}
          className="combobox__indicator combobox__indicator--toggle"
          tabIndex="-1"
          disabled={props.disabled}
        >
          <ArrowDownB />
        </button>
      )}
    </div>
  </Target>
);

ComboboxTrigger.displayName = 'ComboboxTrigger';

ComboboxTrigger.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  disableSearch: PropTypes.bool,
  focusableRef: PropTypes.func,
  toLabel: PropTypes.func.isRequired,
  selectedItem: PropTypes.any,
  toggleMenu: PropTypes.func.isRequired,
  getInputProps: PropTypes.func.isRequired,
  getButtonProps: PropTypes.func.isRequired,
  renderSelected: PropTypes.func.isRequired,
};

export default ComboboxTrigger;
