/* global window */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { IN_WIDGET } from '../constants';

export const widgetSettingsShape = PropTypes.shape({
  chrome: PropTypes.string,
  scheduling: PropTypes.string,
  isSchedulingInWidget: PropTypes.bool,
  partnerId: PropTypes.string,
  hidePartnerLogo: PropTypes.bool,
  aftId: PropTypes.string,
});

// The widget settings will be global until we migrate the widgets completly over to the
// patients-ui application. The higher-order component below is supposed to mimic the one
// used in the patients-ui application to make the transition easy.
let WIDGET_SETTINGS;

export default function withWidgetSettings() {
  return function withWidgetSettingsComponent(WrappedComponent) {
    const componentName = WrappedComponent.displayName || WrappedComponent.name;

    class WithWidgetSettings extends Component {
      static displayName = `withWidgetSettings(${componentName})`;

      constructor(props) {
        super(props);

        if (WIDGET_SETTINGS == null) {
          const { widgetSettings } = window.INITIAL_CONTEXT || {};
          WIDGET_SETTINGS = widgetSettings || {};
          WIDGET_SETTINGS.isSchedulingInWidget = WIDGET_SETTINGS.scheduling === IN_WIDGET;
        }
      }

      render() {
        return <WrappedComponent widgetSettings={WIDGET_SETTINGS} {...this.props} />;
      }
    }

    return hoistNonReactStatics(WithWidgetSettings, WrappedComponent);
  };
}
