/* eslint-disable */

import $ from 'jquery';
import 'jquery.autotab';

$(() =>
  $('.month-input').each(function() {
    const input = $(this);
    const inputs = input.nextAll('[type="text"]');
    return input
      .add(inputs)
      .autotab_magic()
      .autotab_filter('numeric');
  })
);
