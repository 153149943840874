import React from 'react';
import createReactClass from 'create-react-class';
import Reflux from 'reflux';
import ProviderMap from '../components/ProviderMap';
import ProviderDetailStore from '../stores/ProviderDetailStore';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  mixins: [Reflux.ListenerMixin],

  getInitialState() {
    return {
      adjacentProviders: ProviderDetailStore.data.adjacentProviders,
    };
  },

  componentDidMount() {
    this.listenTo(ProviderDetailStore, this.onProviderDetailStoreChange);
  },

  onProviderDetailStoreChange(payload) {
    this.setState({
      adjacentProviders: payload.adjacentProviders,
    });
  },

  render() {
    return <ProviderMap adjacentProviders={this.state.adjacentProviders} />;
  },
});
