/* global document, window */

import $ from 'jquery';
import Reflux from 'reflux';
import ApplicationActions from '../actions/ApplicationActions';

const ApplicationStore = Reflux.createStore({
  listenables: [ApplicationActions],

  init() {
    this.data = {
      googleMapsApi: null,
    };
  },

  onInitialize() {
    this.loadGoogleMapsApi();
  },

  onLoadGoogleMapsApiCompleted() {
    this.data.googleMapsApi = window.google.maps;
    // So our legeacy geolocator plugin knows when google maps is available
    $(window).trigger('googlemaps.load');
    this.trigger(this.data);
  },

  loadGoogleMapsApi() {
    const apiKey = window.GOOGLE_MAPS_API_KEY;
    if (!apiKey) {
      return;
    }
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://maps.googleapis.com/maps/api/js?key=${
      apiKey
    }&callback=ApplicationStore.onLoadGoogleMapsApiCompleted`;
    document.body.appendChild(script);
  },
});

window.ApplicationStore = ApplicationStore;

export default ApplicationStore;
