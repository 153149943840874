import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GlobalInsuranceIssuerSelectGraphQLContainer from '../../graphql/GlobalInsuranceIssuerSelectGraphQLContainer';
import withPartnerData, { partnerDataShape } from '../../utils/withPartnerData';
import PartnerInsuranceIssuerSelect from './insurance-issuer-select/PartnerInsuranceIssuerSelect';

function issuerToValue(issuer) {
  return issuer ? issuer.id : '';
}

function issuerToLabel(issuer) {
  if (!issuer) {
    return '';
  }
  if (issuer.state && issuer.state !== 'US') {
    return `${issuer.display} (${issuer.state})`;
  }
  return issuer.display;
}

class InsuranceIssuerSelect extends Component {
  static propTypes = {
    emptyLabel: PropTypes.string,
    placeholder: PropTypes.string,
    countryCode: PropTypes.string.isRequired,
    partnerData: partnerDataShape,
  };

  static defaultProps = {
    placeholder: 'Select an insurance…',
    countryCode: 'us',
  };

  state = {
    inputValue: '',
    isOpen: false,
  };

  onStateChange = ({ isOpen, inputValue }) => {
    const state = {};
    if (isOpen !== undefined) {
      state.isOpen = isOpen;
      if (isOpen) {
        state.inputValue = '';
      }
    }
    if (inputValue !== undefined) {
      state.inputValue = inputValue;
    }
    this.setState(state);
  };

  shouldUsePartnerInsuranceIssuerClient() {
    const { partnerData } = this.props;
    if (partnerData == null) {
      return false;
    }
    const { loading, partner } = partnerData;
    if (loading) {
      return true;
    }
    if (partner == null) {
      return false;
    }
    return partner.insuranceIssuers.nodes.length > 0;
  }

  render() {
    const SelectComponent = this.shouldUsePartnerInsuranceIssuerClient()
      ? PartnerInsuranceIssuerSelect
      : GlobalInsuranceIssuerSelectGraphQLContainer;
    return (
      <SelectComponent
        {...this.props}
        inputValue={this.state.inputValue}
        isOpen={this.state.isOpen}
        onStateChange={this.onStateChange}
        toLabel={issuerToLabel}
        toValue={issuerToValue}
      />
    );
  }
}

export default withPartnerData()(InsuranceIssuerSelect);
