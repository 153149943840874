import { getGoogleTag } from '../gtag';

// aftId will be in the format of "[conversion_id]/[conversion_label]", i.e.
// "AW-847031165/mwM3CNOI0oIBEP3W8pMD". For the initial page load, we only want the conversion id
// portion. For event tracking we want the full aftId.
function getConversionId(aftId) {
  return decodeURIComponent(aftId).split('/')[0];
}

export async function trackPage(aftId) {
  const conversionId = getConversionId(aftId);
  const gtag = getGoogleTag(conversionId);
  gtag('event', 'page_view');
}

export async function trackEvent(aftId, event) {
  if (event !== 'Scheduled Appointment') {
    return;
  }
  const conversionId = getConversionId(aftId);
  const gtag = getGoogleTag(conversionId);
  gtag('event', 'conversion', {
    send_to: decodeURIComponent(aftId),
  });
}
