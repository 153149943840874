/* global window */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

const WINDOW_PROPERTIES = [
  'left',
  'top',
  'height',
  'width',
  'menubar',
  'toolbar',
  'location',
  'personalbar',
  'directories',
  'status',
  'resizable',
  'scrollbars',
];

export default class PopupWindowLink extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    windowUrl: PropTypes.string.isRequired,
    windowName: PropTypes.string.isRequired,
    center: PropTypes.bool,
    left: PropTypes.number,
    top: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    menubar: PropTypes.bool,
    toolbar: PropTypes.bool,
    location: PropTypes.bool,
    personalbar: PropTypes.bool,
    directories: PropTypes.bool,
    status: PropTypes.bool,
    resizable: PropTypes.bool,
    scrollbars: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    event.preventDefault();
    if (this.popupWindow && !this.popupWindow.closed) {
      this.popupWindow.focus();
    } else {
      this.popupWindow = window.open(
        this.props.windowUrl,
        this.props.windowName,
        this.getWindowProps()
      );
    }
  }

  getWindowProps() {
    const { center, ...windowProps } = this.props;
    if (center) {
      const { width, height } = windowProps;
      const windowTop = window.screenTop ? window.screenTop : window.screenY;
      const windowLeft = window.screenLeft ? window.screenLeft : window.screenX;
      windowProps.top = Math.max(0, windowTop + window.innerHeight / 2 - height / 2);
      windowProps.left = Math.max(0, windowLeft + window.innerWidth / 2 - width / 2);
    }
    return this.buildWindowPropsString(windowProps);
  }

  buildWindowPropsString(props) {
    let propStrings = [];
    WINDOW_PROPERTIES.forEach(prop => {
      let value = props[prop];
      if (typeof value !== 'undefined') {
        if (value === true) {
          value = 'yes';
        } else if (value === false) {
          value = 'no';
        }
        propStrings = [...propStrings, `${prop}=${value}`];
      }
    });
    return propStrings.join(',');
  }

  render() {
    return (
      <a href={this.props.windowUrl} className={this.props.className} onClick={this.onClick}>
        {this.props.children}
      </a>
    );
  }
}
