import React from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';

export const partnerDataShape = PropTypes.shape({
  loading: PropTypes.bool.isRequired,
  partner: PropTypes.shape({
    logo: PropTypes.string,
    widgetDisclaimer: PropTypes.string,
    insuranceIssuers: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          isFeatured: PropTypes.bool.isRequired,
          insuranceIssuer: PropTypes.shape({
            id: PropTypes.string.isRequired,
            display: PropTypes.string.isRequired,
            state: PropTypes.string,
          }).isRequired,
        })
      ).isRequired,
    }).isRequired,
  }),
});

export default function withPartnerData() {
  return function withPartnerDataComponent(WrappedComponent) {
    const componentName = WrappedComponent.displayName || WrappedComponent.name;

    const WithPartnerData = (props, context) => (
      <WrappedComponent {...props} partnerData={context.partnerData} />
    );

    WithPartnerData.displayName = `withPartnerData(${componentName})`;

    WithPartnerData.contextTypes = {
      partnerData: partnerDataShape,
    };

    return hoistNonReactStatics(WithPartnerData, WrappedComponent);
  };
}
