import React from 'react';
import PropTypes from 'prop-types';

import ComboboxOption from '../../base/combobox/ComboboxOption';

const InsuranceIssuerSelectOption = ({ item, ...props }) => (
  <ComboboxOption item={item} {...props}>
    {item.display}{' '}
    {item.state && item.state !== 'US' && <span className="muted">({item.state})</span>}
  </ComboboxOption>
);

InsuranceIssuerSelectOption.displayName = 'InsuranceIssuerSelectOption';

InsuranceIssuerSelectOption.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    state: PropTypes.string,
  }).isRequired,
};

export default InsuranceIssuerSelectOption;
