/* global document */

import $ from 'jquery';

class MarketingHeaderDropdown {
  constructor(element) {
    this.$element = element;
    this.$container = this.$element.parent();
    this.$dropdown = $(`[aria-labelledby="${this.$element.attr('id')}"]`);
    this.$dropdownItems = this.$dropdown.find('.marketing-header__item');

    if (this.$element.data('toggle-on-click')) {
      this.$element.on('click', this.toggle);
    } else {
      this.$container.on('focusin mouseenter', this.open).on('mouseleave', this.close);
    }

    this.state = { expanded: false };
  }

  onGlobalFocus = () => {
    const childFocused = this.$container.get(0).contains(document.activeElement);
    if (!childFocused) {
      this.close();
    }
  };

  onKeyUp = event => {
    if (event.keyCode === 27) {
      this.close();
    }
  };

  open = () => {
    this.update({ expanded: true });
  };

  close = () => {
    this.update({ expanded: false });
  };

  toggle = () => {
    this.update({ expanded: !this.state.expanded });
  };

  update(newState) {
    if (newState.expanded === this.state.expanded) {
      return;
    }
    this.state = newState;
    const { expanded } = this.state;
    this.$element.attr('aria-expanded', expanded ? 'true' : 'false');
    this.$dropdown.attr('aria-hidden', expanded ? 'false' : 'true');
    this.$dropdownItems.attr('tabindex', expanded ? 0 : -1);

    if (expanded) {
      $(document)
        .on('keyup', this.onKeyUp)
        .on('focusin', this.onGlobalFocus);
    } else {
      $(document)
        .off('keyup', this.onKeyUp)
        .off('focusin', this.onGlobalFocus);
    }
  }
}

$(() => {
  $('.marketing-header__item[aria-haspopup="true"]').each(function initMarketingHeaderDropdown() {
    // eslint-disable-next-line no-new
    new MarketingHeaderDropdown($(this));
  });
});
