import React from 'react';
import PropTypes from 'prop-types';
import { widgetizeUrl } from '../../utils/urlUtils';

const WidgetLink = ({
  children,
  href,
  target,
  isOnWidget = false,
  toWidget = true,
  ...linkProps
}) => {
  /* eslint-disable no-param-reassign */
  if (isOnWidget) {
    if (toWidget) {
      href = widgetizeUrl(href);
    } else {
      target = '_top';
    }
  }
  return (
    <a {...linkProps} href={href} target={target}>
      {children}
    </a>
  );
};

WidgetLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
  isOnWidget: PropTypes.bool,
  toWidget: PropTypes.bool,
};

export default WidgetLink;
