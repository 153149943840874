import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import { PropTypes as CustomPropTypes } from 'localmed-openings-calendar/lib/utils/reactUtils';
import ProviderCalendarTable from '../components/provider-calendar/ProviderCalendarTable';
import toISODateString from '../utils/toISODateString';
import { convertOpening } from '../utils/openingUtils';

class ProviderCalendarTableGraphQLContainer extends Component {
  static displayName = 'ProviderCalendarTableGraphQLContainer';

  static propTypes = {
    provider: PropTypes.shape({
      id: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      timeZone: PropTypes.string.isRequired,
    }).isRequired,
    patientStatusId: PropTypes.string,
    reasonForVisitId: PropTypes.string,
    insuranceIssuerId: PropTypes.string,
    selectedDate: CustomPropTypes.moment.isRequired,
    minDate: CustomPropTypes.moment,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      openings: PropTypes.object,
    }),
    onRequestNextAvailableCalendar: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.convertOpening = this.convertOpening.bind(this);
    this.onRequestNextAvailableCalendar = this.onRequestNextAvailableCalendar.bind(this);
    this.state = {
      isLoading: true,
      nextAvailableDate: null,
      openings: [],
    };
    this.hasAdvancedToNextAvailableDate = false;
  }

  componentWillReceiveProps(nextProps) {
    const { data: { loading: isLoading } = {}, onRequestNextAvailableCalendar } = nextProps;
    const openings = this.getOpenings(nextProps);
    const nextAvailableDate = this.getNextAvailableDate(nextProps);

    const shouldAdvanceToNextAvailableDate =
      !this.hasAdvancedToNextAvailableDate &&
      openings.length === 0 &&
      nextAvailableDate != null &&
      onRequestNextAvailableCalendar;

    if (shouldAdvanceToNextAvailableDate) {
      this.hasAdvancedToNextAvailableDate = true;
      onRequestNextAvailableCalendar(nextAvailableDate);
    } else {
      this.setState({ isLoading, openings, nextAvailableDate });
    }
  }

  onRequestNextAvailableCalendar() {
    const { onRequestNextAvailableCalendar } = this.props;
    if (onRequestNextAvailableCalendar) {
      onRequestNextAvailableCalendar(this.getNextAvailableDate());
    }
  }

  getOpenings({ data, ...props } = this.props) {
    if (data == null || data.loading) {
      return [];
    }
    const { openings: { nodes: openings = [] } = {} } = data;
    return openings.map(o => this.convertOpening(o, props));
  }

  getNextAvailableDate({ data, provider: { timeZone } } = this.props) {
    if (data == null || data.loading) {
      return null;
    }
    const { openings: { pageInfo: { nextAvailableDate } = {} } = {} } = data;
    if (nextAvailableDate == null) {
      return null;
    }
    return moment.tz(nextAvailableDate, timeZone);
  }

  convertOpening(opening, props = this.props) {
    if (opening == null) {
      return null;
    }
    const {
      provider: { uri: providerUri, timeZone },
      patientStatusId,
      reasonForVisitId,
      insuranceIssuerId,
      minDate,
    } = props;
    return convertOpening(opening, {
      providerUri,
      timeZone,
      patientStatusId,
      reasonForVisitId,
      insuranceIssuerId,
      minDate,
    });
  }

  render() {
    const { data, ...props } = this.props; // eslint-disable-line no-unused-vars
    // We could get this directly from the props. But when we're advancing
    // to the next availble date, we don't want to show the "Go to next
    // available" message.
    const { isLoading, nextAvailableDate, openings } = this.state;
    return (
      <ProviderCalendarTable
        {...props}
        isLoading={isLoading}
        nextAvailableDate={nextAvailableDate}
        openings={openings}
        onRequestNextAvailableCalendar={this.onRequestNextAvailableCalendar}
      />
    );
  }
}

export default graphql(
  gql`
    query ProviderCalendarTable(
      $providerId: ID!
      $reasonForVisitId: ID!
      $after: String!
      $before: String!
    ) {
      openings(
        providerId: $providerId
        reasonForVisitId: $reasonForVisitId
        after: $after
        before: $before
      ) {
        pageInfo {
          nextAvailableDate
        }
        nodes {
          id
          time
        }
      }
    }
  `,
  {
    options(props) {
      const { provider: { id: providerId }, reasonForVisitId, selectedDate } = props;
      const before = moment(selectedDate).add({ days: 7 });
      return {
        skip: reasonForVisitId == null,
        variables: {
          providerId,
          reasonForVisitId,
          after: toISODateString(selectedDate),
          before: toISODateString(before),
        },
      };
    },
  }
)(ProviderCalendarTableGraphQLContainer);
