export const CASH_PAYMENT_ID = 'lm_xxxxxxx';
export const OTHER_ID = '';

export const INSURANCE_COVERAGE_STATUS = {
  UNKNOWN: 'UNKNOWN',
  CASH_PAYMENT: 'CASH_PAYMENT',
  OTHER: 'OTHER',
  IN_NETWORK: 'IN_NETWORK',
  WILL_FILE: 'WILL_FILE',
  WILL_NOT_FILE: 'WILL_NOT_FILE',
};

export const NEW = 'new';
export const RETURNING = 'returning';

export const IN_WIDGET = 'in_widget';

export const EMBEDDED = 'embedded';

export const REVIEW_SERVICE_TYPE = {
  LOCALMED: 'LOCALMED',
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  YELP: 'YELP',
};

export const DATE_REPRESENTATION = {
  NARROW: 'narrow',
  SHORT: 'short',
  LONG: 'long',
  NUMERIC: 'numeric',
  TWO_DIGIT: '2-digit',
};

export const DATE_FORMAT = {
  MEDIUM_NO_YEAR: {
    month: DATE_REPRESENTATION.LONG,
    day: DATE_REPRESENTATION.NUMERIC,
  },
  SHORT: {
    month: DATE_REPRESENTATION.SHORT,
    day: DATE_REPRESENTATION.TWO_DIGIT,
    year: DATE_REPRESENTATION.NUMERIC,
  },
};

export const DATE_TIME_FORMAT = {
  FULL: {
    year: DATE_REPRESENTATION.NUMERIC,
    month: DATE_REPRESENTATION.SHORT,
    day: DATE_REPRESENTATION.NUMERIC,
    hour: DATE_REPRESENTATION.NUMERIC,
    minute: DATE_REPRESENTATION.NUMERIC,
    timeZoneName: DATE_REPRESENTATION.SHORT,
  },
};
