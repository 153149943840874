/* global window */

import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import Modal from 'localmed-modal';
import { ApolloClient, createNetworkInterface } from 'apollo-client';
import { ApolloProvider } from 'react-apollo';
import { IntlProvider } from 'react-intl';

import ApplicationActions from '../actions/ApplicationActions';
import ProviderMapContainer from '../containers/ProviderMapContainer';
import ProviderPageCalendarContainer from '../containers/ProviderPageCalendarContainer';
import CalendarTimeRenderer from './CalendarTimeRenderer';
import GenericRenderer from './GenericRenderer';
import GenericSelectRenderer from './GenericSelectRenderer';
import LocationMapRenderer from './LocationMapRenderer';
import LoginPopupRenderer from './LoginPopupRenderer';
import OfficeSelectRenderer from './OfficeSelectRenderer';

export default {
  start(selector) {
    this.element = $(selector);
    this.apolloClient = new ApolloClient({
      networkInterface: createNetworkInterface({
        uri: '/graphql',
        opts: { credentials: 'same-origin' },
      }),
    });
    this.initializeContext();
    this.renderComponents();
  },

  initializeContext() {
    const payload = window.INITIAL_CONTEXT || {};
    // TODO: Automatically convert datetimes to moment objects
    if (payload.providerDetail && payload.providerDetail.selectedDate) {
      payload.providerDetail.selectedDate = moment(payload.providerDetail.selectedDate);
    }
    if (
      payload.appointmentDetail &&
      payload.appointmentDetail.appointment &&
      payload.appointmentDetail.appointment.time
    ) {
      const { time, provider: { timeZone } } = payload.appointmentDetail.appointment;
      payload.appointmentDetail.appointment.time = moment.tz(time, timeZone);
    }
    ApplicationActions.initialize(payload);
  },

  renderComponents() {
    Modal.setAppElement(this.element.get(0));

    this.renderComponent('#provider-map', () => <ProviderMapContainer />);
    this.renderComponent('#provider-calendar', () => <ProviderPageCalendarContainer />);
    this.renderComponent('[data-localize-time]', CalendarTimeRenderer);
    this.renderComponent('[data-react-class]', GenericRenderer);
    this.renderComponent('[data-location-map]', LocationMapRenderer);
    this.renderComponent('[data-login-popup]', LoginPopupRenderer);
    this.renderComponent('[data-office-select]', OfficeSelectRenderer);
    this.renderComponent('.react-select', GenericSelectRenderer);
  },

  renderComponent(selector, renderer) {
    $(selector).each((i, element) => {
      ReactDOM.render(
        <IntlProvider locale={window.LOCALE}>
          <ApolloProvider client={this.apolloClient}>{renderer(element)}</ApolloProvider>
        </IntlProvider>,
        element
      );
    });
  },
};
