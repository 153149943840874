import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { DATE_TIME_FORMAT } from '../../constants';
import getTimeZoneFormat from '../../utils/getTimeZoneFormat';
import WidgetLink from '../base/WidgetLink';

const ProviderCalendarOpening = ({
  intl,
  className,
  datetime,
  timeZone,
  selected,
  deleted,
  ...props
}) => {
  const classes = classNames(className, 'calendar-table__opening', {
    'calendar-table__opening--selected': selected,
    'calendar-table__opening--old': deleted,
  });
  const timeZoneFormat = getTimeZoneFormat(timeZone);
  return (
    <WidgetLink className={classes} {...props}>
      <time
        dateTime={datetime.toISOString()}
        title={intl.formatTime(datetime, { timeZone: timeZoneFormat, ...DATE_TIME_FORMAT.FULL })}
      >
        {intl.formatTime(datetime, { timeZone: timeZoneFormat })}
      </time>
    </WidgetLink>
  );
};

ProviderCalendarOpening.displayName = 'ProviderCalendarOpening';

ProviderCalendarOpening.propTypes = {
  intl: intlShape.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
  datetime: PropTypes.shape({
    toISOString: PropTypes.func.isRequired,
  }).isRequired,
  timeZone: PropTypes.string,
  selected: PropTypes.bool,
  deleted: PropTypes.bool,
};

export default injectIntl(ProviderCalendarOpening);
