import React from 'react';
import PropTypes from 'prop-types';
import PopupWindowLink from './base/PopupWindowLink';

const LoginPopup = ({
  children,
  windowUrl = '/auth/login/patient/?next=%2Fauth%2Fclose%2F',
  windowName = 'login_popup',
  windowWidth = 650,
  windowHeight = 650,
}) => (
  <PopupWindowLink
    windowUrl={windowUrl}
    windowName={windowName}
    width={windowWidth}
    height={windowHeight}
    center
  >
    {children}
  </PopupWindowLink>
);

LoginPopup.propTypes = {
  children: PropTypes.node,
  windowUrl: PropTypes.string,
  windowName: PropTypes.string,
  windowWidth: PropTypes.number,
  windowHeight: PropTypes.number,
};

export default LoginPopup;
