import Reflux from 'reflux';
import ApplicationActions from '../actions/ApplicationActions';
import ProviderCalendarActions from '../actions/ProviderCalendarActions';
import ProviderDetailActions from '../actions/ProviderDetailActions';

export default Reflux.createStore({
  listenables: [ApplicationActions, ProviderCalendarActions, ProviderDetailActions],

  init() {
    this.data = {
      provider: {},
      adjacentProviders: [],
      reasonsForVisit: [],
    };
  },

  onInitialize({ providerDetail } = {}) {
    if (providerDetail) {
      this.initializeFromProviderDetail(providerDetail);
    }
  },

  initializeFromProviderDetail(providerDetail) {
    const { provider } = providerDetail;
    this.data.adjacentProviders = providerDetail.adjacentProviders;
    if (provider) {
      this.data.provider = provider;
      this.data.reasonsForVisit = provider.reasonsForVisit;
    }
    this.trigger(this.data);
  },
});
