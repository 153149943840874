import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import OverlayView from 'react-google-maps/lib/OverlayView';

export default class LocationMapMarker extends Component {
  static propTypes = {
    position: PropTypes.object,
    isActive: PropTypes.bool,
    label: PropTypes.node,
    href: PropTypes.string,
    target: PropTypes.string,
    zIndex: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.onMarkerInteraction = this.onMarkerInteraction.bind(this);
  }

  onMarkerInteraction() {
    this.forceUpdate();
  }

  getPixelPositionOffset(width, height) {
    return { x: -(width / 2), y: -height };
  }

  renderMarker() {
    const { isActive, href, zIndex, target, label } = this.props;
    const className = classNames('marker', { 'marker--active': isActive });
    const style = { zIndex };
    if (href) {
      return (
        <a
          href={href}
          className={className}
          target={target}
          onMouseOver={this.onMarkerInteraction}
          onMouseOut={this.onMarkerInteraction}
          style={style}
        >
          {this.renderLabel()}
        </a>
      );
    }
    return (
      <div className={className} style={style}>
        {label}
      </div>
    );
  }

  renderLabel() {
    const { label } = this.props;
    if (label) {
      return label;
    }
    return <i className="icon-localmed" />;
  }

  render() {
    return (
      <OverlayView
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        position={this.props.position}
        getPixelPositionOffset={this.getPixelPositionOffset}
      >
        {this.renderMarker()}
      </OverlayView>
    );
  }
}
