import $ from 'jquery';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Downshift from 'downshift';
import { Manager } from 'react-popper';

import ComboboxMenu from './combobox/ComboboxMenu';
import ComboboxTrigger from './combobox/ComboboxTrigger';

export default class Combobox extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.func.isRequired,
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    disableSearch: PropTypes.bool,
    loading: PropTypes.bool,
    isOpen: PropTypes.bool,
    toLabel: PropTypes.func,
    toValue: PropTypes.func,
    onChange: PropTypes.func,
    renderSelected: PropTypes.func,
  };

  static defaultProps = {
    placeholder: 'Select…',
    toValue: item => String(item),
    renderSelected: ({ selectedItem, toLabel }) => toLabel(selectedItem),
  };

  componentWillReceiveProps({ isOpen }) {
    this.justOpened = !this.props.isOpen && isOpen;
  }

  componentDidUpdate() {
    if (this.justOpened && this.focusableNode) {
      this.focusableNode.focus();
    }
    // This is for backwards compatibility with our jQuery modules. Once the search form
    // is rebuilt in React, this can probably be removed.
    if (this.newValue !== undefined && this.hiddenInputNode) {
      const $hiddenInput = $(this.hiddenInputNode);
      if ($hiddenInput.val() === this.newValue) {
        $hiddenInput.trigger('change');
        this.newValue = undefined;
      }
    }
  }

  onChange = selectedItem => {
    this.newValue = this.props.toValue(selectedItem);
    if (this.props.onChange) {
      this.props.onChange(selectedItem);
    }
  };

  setFocusableNode = node => {
    this.focusableNode = node;
  };

  setHiddenInputNode = node => {
    this.hiddenInputNode = node;
  };

  renderHiddenInput({ selectedItem }) {
    const { id, name, toValue } = this.props;
    if (!name) {
      return null;
    }
    return (
      <input
        type="hidden"
        id={`hidden_${id}`}
        name={name}
        value={toValue(selectedItem) || ''}
        ref={this.setHiddenInputNode}
      />
    );
  }

  renderWithDownshiftProps = downshiftProps => {
    const {
      className,
      children,
      placeholder,
      disabled,
      disableSearch,
      loading,
      toLabel,
      toValue,
      renderSelected,
    } = this.props;
    const comboboxClassName = classNames(
      'combobox',
      {
        'combobox--open': downshiftProps.isOpen,
      },
      className
    );
    const customDownshiftProps = { ...downshiftProps, toLabel, toValue };
    return (
      <div className={comboboxClassName}>
        <ComboboxTrigger
          {...customDownshiftProps}
          placeholder={placeholder}
          disabled={disabled}
          disableSearch={disableSearch}
          loading={loading}
          focusableRef={this.setFocusableNode}
          renderSelected={renderSelected}
        />
        <ComboboxMenu loading={loading} {...customDownshiftProps}>
          {children(customDownshiftProps)}
        </ComboboxMenu>
        {this.renderHiddenInput(customDownshiftProps)}
      </div>
    );
  };

  render() {
    const { className, children, toLabel, ...props } = this.props;
    return (
      <Manager>
        <Downshift {...props} onChange={this.onChange} itemToString={toLabel}>
          {this.renderWithDownshiftProps}
        </Downshift>
      </Manager>
    );
  }
}
