import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { injectIntl, intlShape } from 'react-intl';
import { PropTypes as CustomPropTypes } from 'localmed-openings-calendar/lib/utils/reactUtils';
import Datepicker from '../base/Datepicker';
import FlipdownTransition from '../transitions/FlipdownTransition';
import ScaleupTransition from '../transitions/ScaleupTransition';
import FirstOpening from '../provider-calendar/FirstOpening';
import formatDateRange from '../../utils/formatDateRange';

class ProviderPageCalendarHeader extends Component {
  static propTypes = {
    firstOpening: PropTypes.object,
    showDatepicker: PropTypes.bool,
    minDate: CustomPropTypes.moment,
    startDate: CustomPropTypes.moment,
    endDate: CustomPropTypes.moment,
    onRequestToggleDatepicker: PropTypes.func.isRequired,
    onRequestChangeSelectedDate: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    showDatepicker: false,
    minDate: moment(),
  };

  constructor(props) {
    super(props);
    this.onToggleDatepickerClick = this.onToggleDatepickerClick.bind(this);
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
  }

  onToggleDatepickerClick(event) {
    event.preventDefault();
    this.props.onRequestToggleDatepicker();
  }

  onChangeStartDate(event) {
    this.props.onRequestChangeSelectedDate(moment(event.date));
  }

  renderDatepicker() {
    const { showDatepicker, startDate, minDate } = this.props;
    const calendarMinDate = moment(minDate).add({ days: -1 });

    if (showDatepicker) {
      return (
        <div className="provider-calendar__datepicker">
          <Datepicker
            key="datepicker"
            date={startDate}
            startDate={calendarMinDate}
            onChangeDate={this.onChangeStartDate}
            todayHighlight
          />
        </div>
      );
    }
    return <span key="hideDatepicker" />;
  }

  renderDateRangeText() {
    const { intl, startDate, endDate } = this.props;
    return formatDateRange(intl, startDate, endDate);
  }

  renderFirstOpening() {
    const { firstOpening: opening } = this.props;
    let renderedOpening;
    if (opening) {
      renderedOpening = (
        <div key={opening.id}>
          <FirstOpening opening={opening} isOnWidget={false} isSchedulingInWidget={false} />
        </div>
      );
    } else {
      renderedOpening = <span key="noFirstOpening" />;
    }
    return (
      <div className="provider-calendar__first-opening">
        <ScaleupTransition>{renderedOpening}</ScaleupTransition>
      </div>
    );
  }

  render() {
    return (
      <div className="provider-calendar__header">
        <div className="provider-calendar__title space-bottom">
          <h2 className="h3 no-space">Schedule a Confirmed Appointment</h2>
          <div className="provider-calendar__date hug-top">
            <a href="" onClick={this.onToggleDatepickerClick}>
              <span className="space-right">{this.renderDateRangeText()}</span>
              <small>
                Change Date <i className="icon-calendar" />
              </small>
            </a>
            <FlipdownTransition component={'div'}>{this.renderDatepicker()}</FlipdownTransition>
          </div>
        </div>
        {this.renderFirstOpening()}
      </div>
    );
  }
}

export default injectIntl(ProviderPageCalendarHeader);
