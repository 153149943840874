import Reflux from 'reflux';
import moment from 'moment-timezone';
import ApplicationActions from '../actions/ApplicationActions';
import { getOfficeDetailParams, getProviderDetailParams } from '../utils/paramsUtils';

export default Reflux.createStore({
  listenables: [ApplicationActions],

  init() {
    this.data = {};
  },

  onInitialize({ officeDetail, practiceDetail, providerDetail }) {
    let params = {};
    if (providerDetail) {
      params = getProviderDetailParams(providerDetail, {
        autoSelect: providerDetail.autoSelectReasonForVisit,
      });
      params.providerId = providerDetail.providerId;
    } else if (officeDetail) {
      params = getOfficeDetailParams(officeDetail);
      params.officeId = officeDetail.officeId;
    } else if (practiceDetail) {
      params.practiceId = practiceDetail.practiceId;
      params.location = practiceDetail.location;
      params.point = practiceDetail.point;
      params.radius = practiceDetail.radius;
      params.patientStatusId = practiceDetail.patientStatusId;
      params.reasonForVisitId = practiceDetail.reasonForVisitId;
      params.specialtyId = practiceDetail.specialtyId;
    }
    this.onChangeParams(params);
  },

  onChangeParams({ selectedDate, ...params }) {
    this.data = { ...this.data, ...params };
    if (selectedDate && this.data.minDate) {
      this.data.selectedDate = moment.max([selectedDate, this.data.minDate]);
    }
    this.trigger(this.data);
  },
});
