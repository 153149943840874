import Reflux from 'reflux';
import moment from 'moment-timezone';
import ApplicationActions from '../actions/ApplicationActions';
import ProviderCalendarActions from '../actions/ProviderCalendarActions';
import ProviderDetailActions from '../actions/ProviderDetailActions';
import { getProviderDetailParams } from '../utils/paramsUtils';

export default Reflux.createStore({
  listenables: [ApplicationActions, ProviderCalendarActions, ProviderDetailActions],

  init() {
    this.data = {
      showDatepicker: false,
      provider: null,
      patientStatusId: null,
      reasonForVisitId: null,
      insuranceIssuerId: null,
      minDate: moment(),
      selectedDate: moment(),
    };
  },

  onInitialize({ providerDetail, appointmentDetail } = {}) {
    if (providerDetail) {
      this.initializeFromProviderDetail(providerDetail);
    }
    if (appointmentDetail) {
      this.initializeFromAppointmentDetail(appointmentDetail);
    }
  },

  initializeFromProviderDetail(payload) {
    const params = getProviderDetailParams(payload, {
      autoSelect: payload.autoSelectReasonForVisit,
    });

    this.data.provider = payload.provider;
    this.data.insuranceIssuerId = params.insuranceIssuerId;
    this.data.patientStatusId = params.patientStatusId;
    this.data.minDate = params.minDate;
    this.changeFilters({
      selectedDate: params.selectedDate,
      reasonForVisitId: params.reasonForVisitId,
    });
    this.trigger(this.data);
  },

  initializeFromAppointmentDetail({ appointment: { provider, reasonForVisit } }) {
    this.data.provider = provider;
    if (reasonForVisit) {
      this.changeFilters({ reasonForVisitId: reasonForVisit.id });
    }
    this.trigger(this.data);
  },

  onChangeInsuranceIssuer({ insuranceIssuerId }) {
    this.data.insuranceIssuerId = insuranceIssuerId;
    this.trigger(this.data);
  },

  onChangePatientStatus({ patientStatusId }) {
    this.data.patientStatusId = patientStatusId;
    this.trigger(this.data);
  },

  onChangeReasonForVisit({ reasonForVisitId }) {
    this.changeFilters({ reasonForVisitId });
    this.trigger(this.data);
  },

  onChangeSelectedDate({ selectedDate }) {
    this.changeFilters({ selectedDate });
    this.trigger(this.data);
  },

  onGotoPrevious() {
    const previousWeekDate = moment(this.data.selectedDate).add({ days: -7 });
    this.changeFilters({ selectedDate: previousWeekDate });
    this.trigger(this.data);
  },

  onGotoNext() {
    const nextWeekDate = moment(this.data.selectedDate).add({ days: 7 });
    this.changeFilters({ selectedDate: nextWeekDate });
    this.trigger(this.data);
  },

  onToggleDatepicker() {
    this.data.showDatepicker = !this.data.showDatepicker;
    this.trigger(this.data);
  },

  changeFilters({ selectedDate, reasonForVisitId } = {}) {
    this.data.showDatepicker = false;
    if (selectedDate) {
      this.data.selectedDate = moment.max([selectedDate, this.data.minDate]);
    }
    if (reasonForVisitId) {
      this.data.reasonForVisitId = reasonForVisitId;
    }
  },
});
