import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const BASELINE = 'baseline';
const CENTER = 'center';

export default function createIcon(Component) {
  const Icon = ({ meaningful, className, verticalAlign, spin, ...props }) => {
    const componentProps = props;
    componentProps.className = classNames(
      'svg-icon',
      `svg-icon--${verticalAlign}`,
      spin && 'svg-icon--spin',
      className
    );
    if (meaningful) {
      componentProps.role = 'img';
    } else {
      componentProps['aria-hidden'] = true;
    }
    return <Component {...componentProps} />;
  };

  Icon.displayName = `createIcon(${Component.displayName || Component.name})`;

  Icon.propTypes = {
    className: PropTypes.string,
    meaningful: PropTypes.bool,
    verticalAlign: PropTypes.oneOf([BASELINE, CENTER]),
    spin: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  Icon.defaultProps = {
    width: '1em',
    height: '1em',
    verticalAlign: BASELINE,
  };

  return Icon;
}
