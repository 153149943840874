import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class RadioSet extends Component {
  static propTypes = {
    group: PropTypes.string.isRequired,
    label: PropTypes.string,
    radios: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        checked: PropTypes.bool.isRequired,
        changeEvent: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired,
      })
    ).isRequired,
  };

  renderSegmentControls() {
    const { group } = this.props;
    return (
      <div className="segment-controls">
        {this.props.radios.map((radio, i) => (
          <div key={radio.value} className="segment-controls__group-item">
            <input
              type="radio"
              name={group}
              className="segment-controls__button"
              id={`radio-${i}`}
              value={radio.value}
              checked={radio.checked}
              onChange={radio.changeEvent}
            />
            <label htmlFor={`radio-${i}`} className="segment-controls__label">
              <span className="segment-controls__label-text">{radio.text}</span>
            </label>
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { label } = this.props;
    if (label) {
      return (
        <div className="widget-form-label">
          <div className="small">{label}</div>
          {this.renderSegmentControls()}
        </div>
      );
    }
    return this.renderSegmentControls();
  }
}
