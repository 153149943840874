import $ from 'jquery';
import React from 'react';

import OfficeSelectContainer from '../containers/OfficeSelectContainer';

// This emulates the behavior of our previous "officeSelect" plugin. This and the
// associated `OfficeSelectContainer` should be removed once the appointment form is
// rebuilt in React.
export default function OfficeSelectRenderer(element) {
  element = $(element);
  const select = element.find('select');

  let selectedValue;

  const extractData = option => {
    option = $(option);
    const value = option.attr('value');
    if (option.prop('selected')) {
      selectedValue = value;
    }
    return {
      id: value,
      display: option.text(),
      url: option.attr('data-url'),
      street: option.attr('data-street'),
      cityState: option.attr('data-city-state'),
    };
  };

  const items = $.map(select.children(), extractData);

  return (
    <OfficeSelectContainer
      id={select.attr('id')}
      name={select.attr('name')}
      placeholder={select.attr('placeholder')}
      items={items}
      defaultItemId={selectedValue}
    />
  );
}
