import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Field extends Component {
  static propTypes = {
    children: PropTypes.node,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    wrapperClassName: PropTypes.string,
    spaceBottom: PropTypes.bool,
  };

  static defaultProps = {
    spaceBottom: true,
  };

  // the label above the input,
  // inputs themselves may have their own labels
  renderLabel() {
    const { name, label } = this.props;
    const id = this.props.id || name;

    if (label) {
      return (
        <div className="field-label">
          <label htmlFor={id}>{label}</label>
        </div>
      );
    }
    return null;
  }

  // returns a field input divider which includes
  // children which are defined in the ../forms level
  renderInput() {
    const { children, wrapperClassName } = this.props;
    let className = 'field-input';
    if (wrapperClassName) {
      className = classNames(className, wrapperClassName);
    }
    return <div className={className}>{children}</div>;
  }

  render() {
    const className = classNames(`field ${this.props.name}-field`, {
      'required-field': this.props.isRequired,
      'no-space-bottom': !this.props.spaceBottom,
    });
    return (
      <div className={className}>
        {this.renderLabel()}
        {this.renderInput()}
      </div>
    );
  }
}
