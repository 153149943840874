/* eslint-disable */

import $ from 'jquery';
import throttle from './throttle';
import 'jquery.plugin';

// -----------------------------------------------------------------------------
// Data API
// -----------------------------------------------------------------------------

jQuery(function() {
  const locationContainer = $('.location-container');
  if (!locationContainer.length) {
    return;
  }

  const siteChrome = $(
    '.site__header, .search-form, .site__footer, [data-location-container-chrome]'
  );
  locationContainer.expandHeight({
    chrome: siteChrome,
  });

  return $('.location-box').each(function() {
    const locationBox = $(this);
    const locationBoxList = locationBox.find('.location-box__list');
    const locationBoxChrome = locationBox.find('.location-box__header, .location-box__footer');
    return locationBoxList.expandHeight({
      container: locationContainer,
      chrome: locationBoxChrome,
      offset: -96,
      mode: 'maxHeight',
    });
  });
});

// -----------------------------------------------------------------------------
// expandHeight Plugin API
// -----------------------------------------------------------------------------

$.plugin('expandHeight', {
  defaults: {
    minWidth: 800,
    offset: 0,
    mode: 'height',
  },

  init() {
    this.container = this.options.container != null ? this.options.container : $(window);
    this.chrome = this.options.chrome;
    this.window = $(window);
    this.window.on('resize.expandHeight', throttle(() => this.run()));

    // Run this the first time with a slight delay so any dependent layout
    // stuff is hopefully done beforehand.
    return setTimeout(() => this.run(), 100);
  },

  run() {
    if (this.window.width() > this.options.minWidth) {
      let containerHeight = this.container.height() + this.options.offset;
      if (this.chrome != null ? this.chrome.length : undefined) {
        containerHeight -= this._getTotalHeight(this.chrome);
      }
      return this._setHeight(containerHeight);
    } else {
      return this._resetHeight();
    }
  },

  _setHeight(height) {
    return this.element.css(this.options.mode, height);
  },

  _resetHeight() {
    const value = this.options.mode === 'maxHeight' ? 'none' : 'auto';
    return this.element.css(this.options.mode, value);
  },

  _getTotalHeight(elements) {
    let total = 0;
    elements.each(function() {
      return (total += $(this).outerHeight());
    });
    return total;
  },
});
