/* eslint-disable no-param-reassign */

import { DATE_FORMAT, DATE_REPRESENTATION } from '../constants';

const { LONG, NUMERIC } = DATE_REPRESENTATION;

function toDate(datetime) {
  if (datetime == null) {
    return new Date();
  }
  if (typeof datetime.toDate === 'function') {
    return datetime.toDate();
  }
  return datetime;
}

export default function formatDateRange(intl, start, end) {
  start = toDate(start);
  end = toDate(end);

  // For non-US locales, instead of trying to do an optimal "greatest difference" format,
  // we'll just do full date range with "short" formats.
  if (intl.locale !== 'en-US' && intl.locale !== 'en') {
    const startFomattedDate = intl.formatDate(start, DATE_FORMAT.SHORT);
    const endFormattedDate = intl.formatDate(end, DATE_FORMAT.SHORT);
    return `${startFomattedDate} - ${endFormattedDate}`;
  }

  // For the US locale, we'll do a simple "greatest difference" format.
  if (start.getFullYear() === end.getFullYear()) {
    if (start.getMonth() === end.getMonth()) {
      const startFormattedDate = intl.formatDate(start, { month: LONG, day: NUMERIC });
      const endFormattedDay = intl.formatDate(end, { day: NUMERIC });
      const formattedYear = intl.formatDate(end, { year: NUMERIC });
      return `${startFormattedDate} - ${endFormattedDay}, ${formattedYear}`;
    }
    const startFomattedDate = intl.formatDate(start, { month: LONG, day: NUMERIC });
    const endFormattedDate = intl.formatDate(end, { month: LONG, day: NUMERIC, year: NUMERIC });
    return `${startFomattedDate} - ${endFormattedDate}`;
  }
  const startFomattedDate = intl.formatDate(start, { month: LONG, day: NUMERIC, year: NUMERIC });
  const endFormattedDate = intl.formatDate(end, { month: LONG, day: NUMERIC, year: NUMERIC });
  return `${startFomattedDate} - ${endFormattedDate}`;
}
