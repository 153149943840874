import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, injectIntl, intlShape } from 'react-intl';
import moment from 'moment-timezone';
import OpeningsCalendar from 'localmed-openings-calendar';
import { PropTypes as CustomPropTypes } from 'localmed-openings-calendar/lib/utils/reactUtils';
import { DATE_REPRESENTATION } from '../../constants';
import getTimeZoneFormat from '../../utils/getTimeZoneFormat';
import ProviderCalendarOpening from './ProviderCalendarOpening';

class ProviderCalendarTable extends Component {
  static propTypes = {
    provider: PropTypes.shape({
      phoneNumber: PropTypes.string,
    }).isRequired,
    calendarType: PropTypes.string,
    minDate: CustomPropTypes.moment,
    selectedDate: CustomPropTypes.moment,
    nextAvailableDate: CustomPropTypes.moment,
    openings: PropTypes.array,
    extraOpenings: PropTypes.array,
    selectedOpening: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    isLoading: PropTypes.bool,
    isOnWidget: PropTypes.bool,
    isSchedulingInWidget: PropTypes.bool,
    onRequestPreviousCalendar: PropTypes.func,
    onRequestNextCalendar: PropTypes.func,
    onRequestNextAvailableCalendar: PropTypes.func,
    onOpeningClick: PropTypes.func,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    minDate: moment(),
    calendarType: OpeningsCalendar.AUTO_DETECT_WEEKEND,
    isLoading: true,
    isOnWidget: false,
    isSchedulingInWidget: false,
  };

  getOpenings() {
    const { extraOpenings } = this.props;
    let { openings } = this.props;
    if (extraOpenings) {
      openings = openings.concat(extraOpenings);
    }
    return openings;
  }

  getStartDate() {
    const { selectedDate, minDate } = this.props;
    return minDate.isBefore(selectedDate) ? selectedDate : minDate;
  }

  getTimeZoneFormat() {
    const { openings } = this.props;
    let timeZone;
    if (openings && openings.length > 0) {
      timeZone = openings[0].timeZone;
    }
    return getTimeZoneFormat(timeZone);
  }

  canSchedulePreviousWeek() {
    return this.props.minDate.isBefore(this.props.selectedDate);
  }

  hasPreviousWeek() {
    return moment().isBefore(this.props.selectedDate);
  }

  hasNextWeek() {
    return true;
  }

  renderHeaderDate({ date }) {
    return [
      <FormattedDate key="1" value={date} weekday={DATE_REPRESENTATION.SHORT} />,
      <br key="2" />,
      <FormattedDate
        key="3"
        value={date}
        month={DATE_REPRESENTATION.SHORT}
        day={DATE_REPRESENTATION.NUMERIC}
      >
        {formattedDate => <span className="muted">{formattedDate}</span>}
      </FormattedDate>,
    ];
  }

  renderRowTime = ({ time }) => {
    const { intl } = this.props;
    const formattedTime = intl.formatTime(time, {
      timeZone: this.getTimeZoneFormat(),
      hour: DATE_REPRESENTATION.NUMERIC,
    });
    // Converts "10 A.M." to "10a" to save horizontal space.
    return formattedTime.toLowerCase().replace(/\s*([ap])\.?m\.?/, '$1');
  };

  renderOpening = ({ opening }) => {
    const { isOnWidget, isSchedulingInWidget, onOpeningClick } = this.props;
    let onClick;
    if (onOpeningClick) {
      onClick = event => {
        event.preventDefault();
        onOpeningClick(opening);
      };
    }
    return (
      <ProviderCalendarOpening
        key={opening.id}
        datetime={opening.time}
        timeZone={opening.timeZone}
        href={opening.uri}
        selected={opening.isSelected}
        deleted={opening.isOld}
        isOnWidget={isOnWidget}
        toWidget={isSchedulingInWidget}
        onClick={onClick}
      />
    );
  };

  render() {
    const { provider: { phoneNumber }, isLoading } = this.props;
    const openings = this.getOpenings();
    const disablePrevious = isLoading || !this.canSchedulePreviousWeek() || !this.hasPreviousWeek();
    const disableNext = isLoading || !this.hasNextWeek();
    return (
      <div className="provider-calendar__table">
        <OpeningsCalendar
          calendarType={this.props.calendarType}
          openings={openings}
          selectedDate={this.getStartDate()}
          nextAvailableDate={this.props.nextAvailableDate}
          phoneNumber={phoneNumber}
          isLoading={isLoading}
          onPreviousClick={this.props.onRequestPreviousCalendar}
          onNextClick={this.props.onRequestNextCalendar}
          onNextAvailableClick={this.props.onRequestNextAvailableCalendar}
          onOpeningClick={this.props.onOpeningClick}
          disablePrevious={disablePrevious}
          disableNext={disableNext}
          renderHeaderDate={this.renderHeaderDate}
          renderRowTime={this.renderRowTime}
          renderOpening={this.renderOpening}
        />
      </div>
    );
  }
}

export default injectIntl(ProviderCalendarTable);
