import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import Reflux from 'reflux';
import ProviderPageCalendar from '../components/ProviderPageCalendar';
import ProviderCalendarStore from '../stores/ProviderCalendarStore';
import ProviderDetailStore from '../stores/ProviderDetailStore';
import WidgetSettingsStore from '../stores/WidgetSettingsStore';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  propTypes: {
    calendarType: PropTypes.string,
    isOnWidget: PropTypes.bool,
    provider: PropTypes.object,
    insuranceIssuerId: PropTypes.string,
    insuranceIssuers: PropTypes.array,
    onRequestChangeInsuranceIssuer: PropTypes.func,
  },

  mixins: [Reflux.ListenerMixin],
  getInitialState() {
    return {
      ...this.getStateFromProviderDetailStore(),
      ...this.getStateFromProviderCalendarStore(),
      ...this.getStateFromWidgetSettingsStore(),
    };
  },

  componentDidMount() {
    this.listenTo(ProviderDetailStore, this.onProviderDetailStoreChange);
    this.listenTo(ProviderCalendarStore, this.onProviderCalendarStore);
    this.listenTo(WidgetSettingsStore, this.onWidgetSettingsStore);
  },

  onProviderDetailStoreChange(payload) {
    this.setState(this.getStateFromProviderDetailStore(payload));
  },

  onProviderCalendarStore(payload) {
    this.setState(this.getStateFromProviderCalendarStore(payload));
  },

  onWidgetSettingsStore(payload) {
    this.setState(this.getStateFromWidgetSettingsStore(payload));
  },

  getStateFromProviderDetailStore(payload = ProviderDetailStore.data) {
    return {
      reasonsForVisit: payload.reasonsForVisit,
    };
  },

  getStateFromProviderCalendarStore(payload = ProviderCalendarStore.data) {
    return {
      provider: payload.provider,
    };
  },

  getStateFromWidgetSettingsStore(payload = WidgetSettingsStore.data) {
    return {
      isSchedulingInWidget: payload.isSchedulingInWidget,
    };
  },

  render() {
    return (
      <ProviderPageCalendar
        provider={this.state.provider}
        calendarType={this.props.calendarType}
        reasonsForVisit={this.state.reasonsForVisit}
        isSchedulingInWidget={this.state.isSchedulingInWidget}
        insuranceIssuers={this.props.insuranceIssuers}
      />
    );
  },
});
