import React, { Component } from 'react';
import PropTypes from 'prop-types';

import InsuranceIssuerSelectFieldGraphQLContainer from '../graphql/InsuranceIssuerSelectFieldGraphQLContainer';
import PartnerDataProviderGraphQLContainer from '../graphql/PartnerDataProviderGraphQLContainer';
import withParams from '../utils/withParams';

class InsuranceIssuerChoiceFieldContainer extends Component {
  static propTypes = {
    defaultInsuranceIssuerId: PropTypes.string,
    params: PropTypes.shape({
      insuranceIssuerId: PropTypes.string,
    }).isRequired,
    changeParams: PropTypes.func.isRequired,
  };

  onChange = insuranceIssuer => {
    // We store the selected insurance issuer as a class property rather than in the React
    // state to avoid multiple renders (`changeParams` triggers a state change and render).
    this.selectedInsuranceIssuer = insuranceIssuer;
    this.props.changeParams({ insuranceIssuerId: insuranceIssuer && insuranceIssuer.id });
  };

  render() {
    const { defaultInsuranceIssuerId, params, ...props } = this.props;
    let insuranceIssuerId;
    if (this.selectedInsuranceIssuer === undefined) {
      insuranceIssuerId =
        params.insuranceIssuerId == null ? defaultInsuranceIssuerId : params.insuranceIssuerId;
    } else {
      insuranceIssuerId = this.selectedInsuranceIssuer && this.selectedInsuranceIssuer.id;
    }
    return (
      <PartnerDataProviderGraphQLContainer>
        <InsuranceIssuerSelectFieldGraphQLContainer
          {...props}
          insuranceIssuer={this.selectedInsuranceIssuer}
          insuranceIssuerId={insuranceIssuerId}
          onChange={this.onChange}
        />
      </PartnerDataProviderGraphQLContainer>
    );
  }
}

export default withParams()(InsuranceIssuerChoiceFieldContainer);
