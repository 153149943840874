/* eslint-disable */

import $ from 'jquery';
import throttle from './throttle';
import 'jquery.waypoints-sticky';

$(function() {
  const $window = $(window);
  const $filterbox = $('.filterbox');
  if (!$filterbox.length) {
    return;
  }

  const $column = $filterbox.closest('[class*="column-"]');
  let $stickyWrapper = null;

  const updateStickyMenu = function(direction = 'down') {
    let height, left, width;
    if (!$stickyWrapper) {
      $stickyWrapper = $filterbox.closest('.sticky-wrapper');
    }

    if (direction === 'down' && shouldStick()) {
      width = $column.innerWidth();
      height = $filterbox.outerHeight();
      ({ left } = $column.offset());
    } else {
      [width, height, left] = ['auto', 'auto', 'auto'];
    }

    $stickyWrapper.height(height);
    $filterbox.width(width);
    return $filterbox.css({ left });
  };

  var shouldStick = () => $window.width() > 799;

  $filterbox.waypoint('sticky', {
    offset: 22,
    handler: updateStickyMenu,
  });

  // Throttle the window resize event to every 50ms
  $window.on('resize', throttle(updateStickyMenu));
  return updateStickyMenu();
});
