import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import ApplicationActions from '../actions/ApplicationActions';
import ParamsStore from '../stores/ParamsStore';

export default function withParams() {
  return function withParamsComponent(WrappedComponent) {
    const componentName = WrappedComponent.displayName || WrappedComponent.name;

    class WithParams extends Component {
      static displayName = `withParams(${componentName})`;

      constructor(props) {
        super(props);
        this.state = { params: ParamsStore.data };
      }

      componentDidMount() {
        this.unsubscribeFromParamsStore = ParamsStore.listen(params => this.setState({ params }));
      }

      componentWillUnmount() {
        this.unsubscribeFromParamsStore();
      }

      render() {
        return (
          <WrappedComponent
            params={this.state.params}
            changeParams={ApplicationActions.changeParams}
            {...this.props}
          />
        );
      }
    }

    return hoistNonReactStatics(WithParams, WrappedComponent);
  };
}
