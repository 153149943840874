import { defineMessages } from 'react-intl';
import { DATE_REPRESENTATION } from '../constants';
import getTimeZoneFormat from './getTimeZoneFormat';

const messages = defineMessages({
  lastDay: { id: 'calendarTime.lastDay', defaultMessage: 'Yesterday at {time}' },
  sameDay: { id: 'calendarTime.sameDay', defaultMessage: 'Today at {time}' },
  nextDay: { id: 'calendarTime.nextDay', defaultMessage: 'Tomorrow at {time}' },
  lastWeek: { id: 'calendarTime.lastWeek', defaultMessage: 'Last {weekday} at {time}' },
  nextWeek: { id: 'calendarTime.nextWeek', defaultMessage: '{weekday} at {time}' },
});

function getStartOfToday() {
  const now = new Date();
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);
  return now;
}

// This is based on moment's `calendar` method.
// See: https://github.com/moment/moment/blob/develop/src/lib/moment/calendar.js
function getMessage(datetime) {
  const diff = (datetime - getStartOfToday()) / (3600 * 1000 * 24);
  if (diff < -6) return null;
  if (diff < -1) return messages.lastWeek;
  if (diff < 0) return messages.lastDay;
  if (diff < 1) return messages.sameDay;
  if (diff < 2) return messages.nextDay;
  if (diff < 7) return messages.nextWeek;
  return null;
}

export default function formatCalendarTime(intl, datetime, { timeZone } = {}) {
  if (typeof datetime.toDate === 'function') {
    datetime = datetime.toDate();
  }
  const message = getMessage(datetime);
  const timeZoneFormat = getTimeZoneFormat(timeZone);
  if (message == null) {
    const yearIsDifferent = datetime.getYear() !== new Date().getYear();
    return intl.formatTime(datetime, {
      timeZone: timeZoneFormat,
      month: DATE_REPRESENTATION.LONG,
      day: DATE_REPRESENTATION.NUMERIC,
      year: yearIsDifferent ? DATE_REPRESENTATION.NUMERIC : undefined,
      hour: DATE_REPRESENTATION.NUMERIC,
      minute: DATE_REPRESENTATION.NUMERIC,
    });
  }
  const weekday = intl.formatDate(datetime, {
    timeZone: timeZoneFormat,
    weekday: DATE_REPRESENTATION.LONG,
  });
  const time = intl.formatTime(datetime, { timeZone: timeZoneFormat });
  return intl.formatMessage(message, { weekday, time });
}
