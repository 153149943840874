/* global window */

import $ from 'jquery';
import { trackAffiliateEvent, trackAffiliatePage } from './affiliateTracking';
import { trackEvent } from './tracking';

$(() => {
  const { AFT_ID: aftId, AFT_TYPE: aftType, EVENT_DATA: eventData } = window.CLIENT_ENV || {};
  trackAffiliatePage(aftId, aftType);
  if (!eventData) {
    return;
  }
  if (!eventData.events) {
    return;
  }
  eventData.events.forEach(event => {
    trackEvent(event.name, event.properties);
    trackAffiliateEvent(aftId, aftType, event.name, event.properties);
  });
});
