import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { INSURANCE_COVERAGE_STATUS } from '../../constants';
import Field from '../base/Field';
import Message, { SUCCESS, PRIMARY, WARNING, ERROR } from '../base/Message';
import ScaleupTransition from '../transitions/ScaleupTransition';
import InsuranceIssuerSelect from './InsuranceIssuerSelect';

const COVERAGE_MESSAGE_TYPES = {
  [INSURANCE_COVERAGE_STATUS.UNKNOWN]: WARNING,
  [INSURANCE_COVERAGE_STATUS.CASH_PAYMENT]: PRIMARY,
  [INSURANCE_COVERAGE_STATUS.OTHER]: PRIMARY,
  [INSURANCE_COVERAGE_STATUS.IN_NETWORK]: SUCCESS,
  [INSURANCE_COVERAGE_STATUS.WILL_FILE]: PRIMARY,
  [INSURANCE_COVERAGE_STATUS.WILL_NOT_FILE]: ERROR,
};

export default class InsuranceIssuerSelectField extends Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    coverage: PropTypes.shape({
      status: PropTypes.oneOf(Object.keys(INSURANCE_COVERAGE_STATUS)).isRequired,
      message: PropTypes.string.isRequired,
    }),
    insuranceIssuerId: PropTypes.string,
    isOnWidget: PropTypes.bool,
    spaceBottom: PropTypes.bool,
  };

  static defaultProps = {
    label: 'How will you be paying?',
    name: 'insurance-issuer',
    coverage: null,
    spaceBottom: null,
  };

  renderMessage() {
    const { coverage } = this.props;
    if (coverage == null) {
      return <span key="noMessage" />;
    }
    const { status, message } = coverage;
    return (
      <Message
        key={status}
        type={COVERAGE_MESSAGE_TYPES[status]}
        className="space-top lm-no-bottom-margin"
      >
        {message}
      </Message>
    );
  }

  render() {
    const { name, label, spaceBottom, ...props } = this.props;
    const id = this.props.id || name;
    return (
      <Field id={id} name={name} label={label} spaceBottom={spaceBottom}>
        <InsuranceIssuerSelect {...props} id={id} name={name} />
        <ScaleupTransition>{this.renderMessage()}</ScaleupTransition>
      </Field>
    );
  }
}
