import React from 'react';
import PropTypes from 'prop-types';

const ComboboxMessage = ({ children, ...props }) => (
  <p className="combobox__message" {...props}>
    {children}
  </p>
);

ComboboxMessage.displayName = 'ComboboxMessage';

ComboboxMessage.propTypes = {
  children: PropTypes.node,
};

export default ComboboxMessage;
