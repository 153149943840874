import { pauseClickForTracking, trackEvent } from '../../main/tracking';

export default {
  onClick(event) {
    const { trackingEventName, trackingEventProperties } = this.props;
    if (trackingEventName != null) {
      pauseClickForTracking(event);
      trackEvent(trackingEventName, trackingEventProperties)
    }
  },
};
