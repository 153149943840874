/* global window */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Select from '../components/base/Select';
import OfficeInfo from '../components/OfficeInfo';
import { buildProviderUrl } from '../utils/urlUtils';
import withParams from '../utils/withParams';

class OfficeSelectContainer extends Component {
  static propTypes = {
    defaultItemId: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ).isRequired,
    params: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      itemId: props.defaultItemId,
    };
  }

  onChange = itemId => {
    this.setState({ itemId });
    const { items, params } = this.props;
    const selectedProvider = items.find(item => item.id === itemId);
    if (selectedProvider) {
      window.location.href = buildProviderUrl(selectedProvider.url, params);
    }
  };

  renderOption = ({ item, index }) => (
    <OfficeInfo label={index + 1} street={item.street} cityStateAndPostalCode={item.cityState} />
  );

  renderSelected = ({ selectedItem }) => {
    const index = this.props.items.indexOf(selectedItem);
    const label = index === -1 ? null : index + 1;
    return (
      <OfficeInfo
        label={label}
        street={selectedItem.street}
        cityStateAndPostalCode={selectedItem.cityState}
        isActive
      />
    );
  };

  render() {
    return (
      <Select
        {...this.props}
        itemId={this.state.itemId}
        onChange={this.onChange}
        renderOption={this.renderOption}
        renderSelected={this.renderSelected}
        disableSearch
      />
    );
  }
}

export default withParams()(OfficeSelectContainer);
