/* global navigator */
/* eslint-disable new-cap */

import $ from 'jquery';

const isMobile = {
  Android() {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry() {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS() {
    const iDevices = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ];

    while (iDevices.length) {
      if (navigator.platform === iDevices.pop()) {
        return true;
      }
    }

    return false;
  },
  Opera() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows() {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any() {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

/* eslint-disable prefer-arrow-callback */
$(function onReady() {
  if (isMobile.iOS()) {
    $('a[data-ios-href]').each(function setIOSLinks() {
      const link = $(this);
      link.attr('href', link.data('ios-href'));
    });
  }
});
