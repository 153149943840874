import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import GoogleMap from 'react-google-maps/lib/GoogleMap';
import GoogleMapLoader from 'react-google-maps/lib/GoogleMapLoader';
import sortBy from 'lodash.sortby';
import LocationMapMarker from './location-map/LocationMapMarker';
import LocationMapCluster, { SMALL, LARGE } from './location-map/LocationMapCluster';
import {
  createLatLng,
  createLatLngBounds,
  getCenterWithOffset,
  getZoomByBounds,
} from '../utils/mapUtils';

export default class LocationMap extends Component {
  static propTypes = {
    className: PropTypes.string,
    googleMapsApi: PropTypes.object,
    bounds: PropTypes.shape({
      sw: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
      }).isRequired,
      ne: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
      }).isRequired,
    }),
    fitOverlays: PropTypes.bool,
    frame: PropTypes.object,
    framePadding: PropTypes.number,
    clusters: PropTypes.arrayOf(
      PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
        uri: PropTypes.string,
      })
    ),
    markers: PropTypes.arrayOf(
      PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
        uri: PropTypes.string,
        isActive: PropTypes.bool,
      })
    ),
    defaultZoom: PropTypes.number,
    minZoom: PropTypes.number,
    maxZoom: PropTypes.number,
    mapTypeId: PropTypes.string,
    disableDefaultUI: PropTypes.bool,
    scrollwheel: PropTypes.bool,
  };

  static defaultProps = {
    defaultZoom: 11,
    minZoom: 4,
    maxZoom: 15,
    framePadding: -24,
    disableDefaultUI: true,
    scrollwheel: false,
  };

  constructor(props) {
    super(props);
    this.onProjectionChanged = this.onProjectionChanged.bind(this);
    this.setGoogleMapRef = this.setGoogleMapRef.bind(this);
    this.setRootRef = this.setRootRef.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
  }

  componentWillUpdate() {
    this.updateDimensions();
  }

  componentDidUpdate() {
    if (this.googleMap != null) {
      this.positionMap(this.googleMap.props.map);
    }
  }

  // Re-render everything when the projection changes
  onProjectionChanged() {
    this.forceUpdate();
  }

  getRectCenterX(rect) {
    return rect.left + rect.width / 2;
  }

  getBounds() {
    const { bounds, fitOverlays } = this.props;
    if (bounds) {
      return bounds;
    }
    if (fitOverlays) {
      return this.getBoundsFromOverlays();
    }
    return null;
  }

  getBoundsFromOverlays() {
    const { googleMapsApi, clusters, markers } = this.props;
    const overlays = (clusters || []).concat(markers || []);
    if (!(googleMapsApi && overlays.length)) {
      return null;
    }
    const bounds = new googleMapsApi.LatLngBounds();
    overlays.forEach(overlay => bounds.extend(createLatLng(overlay)));
    return bounds;
  }

  getActiveOverlay() {
    const { markers } = this.props;
    if (markers && markers.length === 1) {
      return markers[0];
    }
    return markers.find(marker => marker.isActive);
  }

  setGoogleMapRef(googleMap) {
    this.googleMap = googleMap;
  }

  setRootRef(root) {
    // eslint-disable-next-line react/no-find-dom-node
    this.root = ReactDOM.findDOMNode(root);
  }

  positionMap(map) {
    if (this.positioned) {
      return;
    }
    const bounds = this.getBounds();
    const { frame } = this.props;
    if (bounds) {
      if (frame) {
        this.positioned = this.positionMapInFrame(map, bounds, frame);
      } else {
        this.positioned = this.positionMapInBounds(map, bounds);
      }
    } else {
      this.positioned = this.positionMapToActiveOverlay(map);
    }
  }

  // Get the dimensions from the component node for positioning the map
  updateDimensions() {
    if (this.root) {
      this.nodeRect = this.root.getBoundingClientRect();
    }
  }

  // This sizes and positions the map so that the bounds (important content)
  // is visible within the target element.
  positionMapInFrame(map, bounds, frame) {
    // Wait for a map projection before positioning
    const projection = map.getProjection();
    if (!projection || !this.nodeRect) {
      return false;
    }

    const { framePadding, minZoom, maxZoom } = this.props;
    const mapBounds = createLatLngBounds(bounds);
    const frameRect = frame.getBoundingClientRect();

    // By default don't reposition the map
    let offsetX = 0;
    let offsetY = 0;
    let targetWidth = this.nodeRect.width;
    let targetHeight = this.nodeRect.height;
    const diffX = frameRect.left - this.nodeRect.left;
    const diffY = frameRect.top - this.nodeRect.top;

    // If frame X is within node bounds, offset the map X to fit in frame
    if (diffX < targetWidth) {
      offsetX = this.getRectCenterX(frameRect) - this.getRectCenterX(this.nodeRect);
      targetWidth = frameRect.width;
    }

    // If frame Y is within node bounds, offset the map Y to fit in frame
    if (diffY < targetHeight) {
      offsetY = diffY / 2;
      targetHeight -= diffY;
    }

    let zoom = getZoomByBounds(projection, mapBounds, targetWidth, targetHeight, framePadding);
    zoom = Math.min(maxZoom, Math.max(minZoom, zoom));
    const center = getCenterWithOffset(projection, zoom, mapBounds.getCenter(), offsetX, offsetY);
    map.setOptions({ zoom, center });
    return true;
  }

  positionMapInBounds(map, bounds) {
    const mapBounds = createLatLngBounds(bounds);
    map.fitBounds(mapBounds);
    return true;
  }

  positionMapToActiveOverlay(map) {
    const { defaultZoom } = this.props;
    const activeOverlay = this.getActiveOverlay();
    let center;
    if (activeOverlay) {
      center = { lat: activeOverlay.lat, lng: activeOverlay.lng };
    }
    map.setOptions({ center, zoom: defaultZoom });
    return true;
  }

  zSortOverlays(overlays) {
    // eslint-disable-next-line no-param-reassign
    overlays = sortBy(overlays, overlay => overlay.props.position.lat);
    overlays.reverse();
    return overlays.map((overlay, index) =>
      // eslint-disable-next-line react/no-array-index-key
      React.cloneElement(overlay, { key: index, zIndex: index + 1 })
    );
  }

  mapClusterCountToSizeRange(
    count = 1,
    minCount = 0,
    maxCount = 1,
    minSize = SMALL,
    maxSize = LARGE
  ) {
    if (count === maxCount) {
      return maxSize;
    }
    return Math.floor((count - minCount) * maxSize / maxCount) + minSize;
  }

  renderOverlays() {
    let overlays = [];
    const { clusters, markers } = this.props;
    if (clusters && clusters.length > 0) {
      overlays = overlays.concat(this.renderClusters(clusters));
    }
    if (markers) {
      if (markers.length === 1) {
        overlays.push(this.renderMarker(markers[0]));
      } else {
        overlays = overlays.concat(this.renderMarkers(markers));
      }
    }
    return this.zSortOverlays(overlays);
  }

  renderClusters(clusters) {
    // eslint-disable-next-line no-param-reassign
    clusters = sortBy(clusters, cluster => cluster.count);
    const minCount = clusters[0].count;
    const maxCount = clusters[clusters.length - 1].count;
    return clusters.map(this.renderCluster.bind(this, minCount, maxCount));
  }

  renderCluster(minCount, maxCount, cluster) {
    const size = this.mapClusterCountToSizeRange(cluster.count, minCount, maxCount);
    return (
      <LocationMapCluster
        position={{ lat: cluster.lat, lng: cluster.lng }}
        size={size}
        href={cluster.uri}
      />
    );
  }

  renderMarkers(markers) {
    return markers.map(this.renderMarker);
  }

  renderMarker(marker, index = null) {
    const props = {
      position: { lat: marker.lat, lng: marker.lng },
      href: marker.uri,
      target: marker.target,
      isActive: marker.isActive,
    };
    if (index == null) {
      props.isActive = true;
    } else {
      props.label = index + 1;
    }
    return <LocationMapMarker {...props} />;
  }

  render() {
    const { googleMapsApi } = this.props;
    if (!googleMapsApi) {
      return <noscript />;
    }
    return (
      <GoogleMapLoader
        ref={this.setRootRef}
        containerElement={<div className={this.props.className} />}
        googleMapElement={
          <GoogleMap
            ref={this.setGoogleMapRef}
            options={{
              mapTypeId: googleMapsApi.MapTypeId.ROADMAP,
              disableDefaultUI: this.props.disableDefaultUI,
              scrollwheel: this.props.scrollwheel,
            }}
            positionMap={this.positionMap}
            onProjectionChanged={this.onProjectionChanged}
          >
            {this.renderOverlays()}
          </GoogleMap>
        }
      />
    );
  }
}
