/* global google */

/*
 * Create a LatLng object from a POJO.
 *
 *   createLatLng(lat: 0, lng: 0});
 */
export function createLatLng(object) {
  if (object instanceof google.maps.LatLng) {
    return object;
  }
  return new google.maps.LatLng(object.lat, object.lng);
}

/*
 * Create a LatLngBounds object from a POJO.
 *
 *   createLatLngBounds({
 *     sw: {lat: 0, lng: 0},
 *     ne: {lat: 1, lng: 1},
 *   });
 */
export function createLatLngBounds(object) {
  if (object instanceof google.maps.LatLngBounds) {
    return object;
  }
  return new google.maps.LatLngBounds(createLatLng(object.sw), createLatLng(object.ne));
}

/*
 * Offset the map's center point by the given x and y values (in pixels).
 *
 * See: http://stackoverflow.com/questions/10656743/how-to-offset-the-center-point-in-google-maps-api-v3
 */
export function getCenterWithOffset(projection, zoom, center, offsetX, offsetY) {
  const scale = 2 ** zoom;
  const point1 = projection.fromLatLngToPoint(center);
  const point2 = new google.maps.Point(offsetX / scale || 0, offsetY / scale || 0);
  return projection.fromPointToLatLng(
    new google.maps.Point(point1.x - point2.x, point1.y - point2.y)
  );
}

const MIN_ZOOM = 0;
const MAX_ZOOM = 21;

/*
 * Calculate the zoom for a map based on the given bounds and containing
 * dimensions.
 *
 * See: http://stackoverflow.com/questions/9837017/equivalent-of-getboundszoomlevel-in-gmaps-api-3
 */
export function getZoomByBounds(projection, bounds, width, height, padding = 0) {
  /* eslint-disable no-bitwise, no-plusplus */
  const ne = projection.fromLatLngToPoint(bounds.getNorthEast());
  const sw = projection.fromLatLngToPoint(bounds.getSouthWest());
  const worldCoordWidth = Math.abs(ne.x - sw.x);
  const worldCoordHeight = Math.abs(ne.y - sw.y);
  for (let zoom = MAX_ZOOM; zoom >= MIN_ZOOM; --zoom) {
    const fitsInWidth = worldCoordWidth * (1 << zoom) + 2 * padding < width;
    const fitsInHeight = worldCoordHeight * (1 << zoom) + 2 * padding < height;
    if (fitsInWidth && fitsInHeight) {
      return zoom;
    }
  }
  return MIN_ZOOM;
}
