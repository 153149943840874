import $ from 'jquery';
import React from 'react';

import GenericSelectContainer from '../containers/GenericSelectContainer';

export default function GenericSelectRenderer(element) {
  element = $(element);
  const select = element.find('select');

  let selectedValue;

  const extractData = option => {
    option = $(option);
    if (option.prop('tagName') === 'OPTGROUP') {
      return {
        display: option.attr('label'),
        children: $.map(option.children(), extractData),
      };
    }
    const value = option.attr('value');
    if (option.prop('selected')) {
      selectedValue = value;
    }
    return {
      id: value,
      display: option.text(),
    };
  };

  const items = $.map(select.children(), extractData);

  return (
    <GenericSelectContainer
      id={select.attr('id')}
      name={select.attr('name')}
      placeholder={select.attr('placeholder')}
      items={items}
      defaultItemId={selectedValue}
    />
  );
}
