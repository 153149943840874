export default function findInTree(items, predicate) {
  let foundItem = null;
  const findItem = item => {
    if (foundItem) {
      return;
    }
    if (item.children != null) {
      item.children.forEach(findItem);
    } else if (predicate(item)) {
      foundItem = item;
    }
  };
  items.forEach(findItem);
  return foundItem;
}
