let CACHED_RESULT = null;

export default function supportsTimeZoneFormat() {
  if (CACHED_RESULT == null) {
    try {
      new Intl.DateTimeFormat('en-US', {
        timeZone: 'Australia/Sydney',
        timeZoneName: 'long',
      }).format();
      CACHED_RESULT = true;
    } catch (error) {
      CACHED_RESULT = false;
    }
  }
  return CACHED_RESULT;
}
