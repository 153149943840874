import React from 'react';
import PropTypes from 'prop-types';
import { Popper } from 'react-popper';

import ComboboxMessage from './ComboboxMessage';

const ComboboxMenu = ({ children, loading, inputValue }) => {
  let content = children;
  if (loading) {
    content = <ComboboxMessage>Loading…</ComboboxMessage>;
  } else if (React.Children.toArray(children).length === 0) {
    content = (
      <ComboboxMessage>
        {inputValue === '' ? 'No results' : `No results for "${inputValue}"`}
      </ComboboxMessage>
    );
  }
  return (
    <Popper
      className="combobox__menu"
      placement="bottom-start"
      modifiers={{
        flip: { enabled: false },
        computeStyle: { gpuAcceleration: false },
      }}
    >
      {content}
    </Popper>
  );
};

ComboboxMenu.displayName = 'ComboboxMenu';

ComboboxMenu.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  inputValue: PropTypes.string.isRequired,
};

export default ComboboxMenu;
