import Reflux from 'reflux';
import ApplicationActions from '../actions/ApplicationActions';
import { IN_WIDGET } from '../constants';

export default Reflux.createStore({
  listenables: [ApplicationActions],

  init() {
    this.data = {
      isSchedulingInWidget: false,
    };
  },

  onInitialize({ widgetSettings } = {}) {
    if (widgetSettings) {
      this.data.isSchedulingInWidget = widgetSettings.scheduling === IN_WIDGET;
      this.trigger(this.data);
    }
  },
});
