/* eslint-disable */

import $ from 'jquery';
import 'jquery.get-target';

$(document).on('click', '[data-dismiss="notice"]', function(event) {
  $(this)
    .getTarget()
    .slideUp();
  return false;
});
