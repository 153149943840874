import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import $ from 'jquery';
import { PropTypes as CustomPropTypes } from 'localmed-openings-calendar/lib/utils/reactUtils';

function convertToCurrentTimeZone(date) {
  const currentUtcOffset = moment().utcOffset();
  return moment(date)
    .add({ minutes: currentUtcOffset * -1 })
    .toDate();
}

export default class Datepicker extends Component {
  static propTypes = {
    date: CustomPropTypes.moment,
    startDate: CustomPropTypes.moment,
    autoclose: PropTypes.bool,
    todayHighlight: PropTypes.bool,
    todayBtn: PropTypes.any,
    onChangeDate: PropTypes.func,
  };

  static defaultProps = {
    startDate: moment().add({ days: -1 }),
  };

  constructor(props) {
    super(props);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.setNodeRef = this.setNodeRef.bind(this);
  }

  componentDidMount() {
    this.createDatepicker().on('changeDate.reactdatepicker', this.onChangeDate);
  }

  componentDidUpdate(/* prevProps, nextProps */) {
    this.destroyDatepicker();
    this.createDatepicker();
  }

  componentWillUnmount() {
    this.destroyDatepicker().off('.reactdatepicker');
  }

  onChangeDate(event) {
    const { onChangeDate } = this.props;
    if (!onChangeDate) {
      return;
    }
    // The selected dates are in UTC ("2017-11-30T00:00:00Z"), and are incorrect when converted
    // to the local time zone ("2017-11-29T18:00:00-06:00"). This keeps the originally selected
    // date but changes the offset to the local time zone ("2017-11-30T00:00:00-06:00").
    event.date = convertToCurrentTimeZone(event.date);
    onChangeDate(event);
  }

  setNodeRef(node) {
    this.$node = $(node);
  }

  createDatepicker() {
    this.$node.datepicker({
      autoclose: this.props.autoclose,
      todayBtn: this.props.todayBtn,
      todayHighlight: this.props.todayHighlight,
      startDate: this.props.startDate.toDate(),
    });
    if (this.props.date) {
      this.$node.datepicker('update', this.props.date.toDate());
    }
    return this.$node;
  }

  destroyDatepicker() {
    this.$node.datepicker('remove');
    return this.$node;
  }

  render() {
    return <div ref={this.setNodeRef} />;
  }
}
