import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Combobox from '../base/Combobox';
import ComboboxOption from '../base/combobox/ComboboxOption';
import ComboboxSectionTitle from '../base/combobox/ComboboxSectionTitle';
import findInTree from './select/findInTree';
import filterTree from './select/filterTree';

function idAsValue(item) {
  return item ? item.id : null;
}

function displayAsLabel(item) {
  return item ? item.display : '';
}

function normalizeInputValue(inputValue) {
  return inputValue ? inputValue.toLowerCase() : '';
}

const itemShape = PropTypes.shape({
  id: PropTypes.string,
  display: PropTypes.string.isRequired,
});
itemShape.children = PropTypes.arrayOf(itemShape);

export default class Select extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(itemShape).isRequired,
    itemId: PropTypes.string,
    toLabel: PropTypes.func.isRequired,
    toValue: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    openUntilSelected: PropTypes.bool,
    renderOption: PropTypes.func.isRequired,
  };

  static defaultProps = {
    toLabel: displayAsLabel,
    toValue: idAsValue,
    renderOption: ({ item, toLabel }) => toLabel(item),
  };

  state = {
    inputValue: undefined,
    isOpen: false,
  };

  onChange = selectedItem => {
    const { onChange, toValue } = this.props;
    if (onChange) {
      onChange(toValue(selectedItem));
    }
  };

  onStateChange = ({ isOpen, inputValue }) => {
    const state = {};
    if (isOpen !== undefined) {
      state.isOpen = isOpen;
      if (isOpen) {
        state.inputValue = '';
      }
    }
    if (inputValue !== undefined) {
      state.inputValue = inputValue;
    }
    this.setState(state);
  };

  getFilteredItems = comboboxProps => {
    const { selectedItem, toLabel } = comboboxProps;
    let { inputValue } = comboboxProps;
    const { items } = this.props;
    inputValue = normalizeInputValue(inputValue);
    if (inputValue === '' || normalizeInputValue(toLabel(selectedItem)) === inputValue) {
      return items;
    }
    return filterTree(items, item => normalizeInputValue(toLabel(item)).indexOf(inputValue) !== -1);
  };

  findSelectedItem() {
    const { itemId, items, toValue } = this.props;
    return findInTree(items, item => toValue(item) === itemId);
  }

  renderMenu = comboboxProps => {
    const items = this.getFilteredItems(comboboxProps);
    const { toValue, toLabel } = comboboxProps;

    const menu = [];
    let index = 0;

    const renderItem = item => {
      if (item.children != null) {
        const label = toLabel(item);
        menu.push(<ComboboxSectionTitle key={label}>{label}</ComboboxSectionTitle>);
        item.children.forEach(renderItem);
      } else {
        menu.push(
          <ComboboxOption {...comboboxProps} key={toValue(item)} item={item} index={index}>
            {this.props.renderOption({ item, index, ...comboboxProps })}
          </ComboboxOption>
        );
        index += 1;
      }
    };

    items.forEach(renderItem);
    return menu;
  };

  render() {
    const { openUntilSelected, ...props } = this.props;
    const selectedItem = this.findSelectedItem();
    let { isOpen } = this.state.isOpen;
    if (openUntilSelected && !this.props.disabled && !selectedItem) {
      isOpen = true;
    }
    return (
      <Combobox
        {...props}
        isOpen={isOpen}
        inputValue={this.state.inputValue}
        selectedItem={selectedItem}
        onStateChange={this.onStateChange}
        onChange={this.onChange}
      >
        {this.renderMenu}
      </Combobox>
    );
  }
}
