import createReactClass from 'create-react-class';
import { PropTypes as CustomPropTypes } from 'localmed-openings-calendar/lib/utils/reactUtils';
import PropTypes from 'prop-types';
import React from 'react';
import TrackClickMixin from '../../mixins/TrackClickMixin';
import CalendarTime from '../CalendarTime';
import WidgetLink from '../base/WidgetLink';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  propTypes: {
    opening: PropTypes.shape({
      uri: PropTypes.string.isRequired,
      time: CustomPropTypes.moment.isRequired,
      timeZone: PropTypes.string,
    }).isRequired,
    isOnWidget: PropTypes.bool,
    isSchedulingInWidget: PropTypes.bool,
    trackingEventName: PropTypes.string,
    trackingEventProperties: PropTypes.object,
  },

  mixins: [TrackClickMixin],

  getDefaultProps() {
    return {
      isOnWidget: false,
      isSchedulingInWidget: false,
      trackingEventName: 'Clicked Next Opening on Provider Profile',
      trackingEventProperties: {},
    };
  },

  render() {
    const { opening, isOnWidget, isSchedulingInWidget } = this.props;
    return (
      <WidgetLink
        href={opening.uri}
        className="lm-button lm-button--primary small"
        onClick={this.onClick}
        isOnWidget={isOnWidget}
        toWidget={isSchedulingInWidget}
      >
        <span>First Available: </span>
        <CalendarTime datetime={opening.time} timeZone={opening.timeZone} />
      </WidgetLink>
    );
  },
});
