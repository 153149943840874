/* eslint-disable */

import $ from 'jquery';

$(function() {
  // Whenever an input changes with given names, automatically any update
  // dependent links with the new value. For instance:
  //
  //   <input name="patient_status_id" value="new">
  //   <a href="https://www.localmed.com/?patient_status_id=new">Link</a>
  //
  // The 'patient_status_id' query string parameter will automatically be
  // updated whenever the input is changed.
  const names = ['reason_for_visit_id', 'insurance_issuer_id'];
  return names.map(name =>
    (function(name) {
      const paramPattern = new RegExp(`${name}(=|%3D)[^&%]*`, 'gi');
      return $(document).on('change', `[name='${name}']`, function(event) {
        const newValue = $(this).val();
        $(`[name='${name}']`)
          .not(this)
          .val(newValue);
        return $(`[href*='${name}']`).attr('href', (i, value) =>
          value.replace(paramPattern, `${name}$1${newValue}`)
        );
      });
    })(name)
  );
});
