import $ from 'jquery';
import React from 'react';
import moment from 'moment-timezone';
import CalendarTime from '../components/CalendarTime';

export default function CalendarTimeRenderer(element) {
  const $element = $(element);
  const timeZoneOffset = $element.data('localize-time-zone');
  let datetime = moment($element.data('localize-time'));
  if (timeZoneOffset) {
    datetime = datetime.utcOffset(timeZoneOffset);
  }
  return <CalendarTime datetime={datetime} />;
}
