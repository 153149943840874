import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class OfficeInfo extends Component {
  static propTypes = {
    isActive: PropTypes.bool,
    label: PropTypes.node,
    street: PropTypes.string.isRequired,
    cityStateAndPostalCode: PropTypes.string.isRequired,
  };

  static defualtProps = {
    isActive: false,
  };

  renderLabel() {
    const { label } = this.props;
    return label || <i className="icon-localmed" />;
  }

  render() {
    const { street, cityStateAndPostalCode, isActive } = this.props;
    const markerClasses = classNames('marker', { 'marker--active': isActive });
    return (
      <div className="media marker-media">
        <div className="media-obj">
          <div className={markerClasses}>{this.renderLabel()}</div>
        </div>
        <div className="media-body">
          <span className="ellipsis">{street}</span>
          <span className="ellipsis">{cityStateAndPostalCode}</span>
        </div>
      </div>
    );
  }
}
