import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { partnerDataShape } from '../../../utils/withPartnerData';
import Combobox from '../../base/Combobox';
import SuggestedInsuranceIssuersMenu from './SuggestedInsuranceIssuersMenu';
import InsuranceIssuerSelectOption from './InsuranceIssuerSelectOption';

function normalizeInputValue(inputValue) {
  return inputValue ? inputValue.toLowerCase() : '';
}

export default class PartnerInsuranceIssuerSelect extends Component {
  static propTypes = {
    insuranceIssuer: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    insuranceIssuerId: PropTypes.string,
    emptyLabel: PropTypes.string,
    partnerData: partnerDataShape.isRequired,
  };

  getPartnerInsuranceIssuers() {
    const { partnerData: { partner } } = this.props;
    return (partner && partner.insuranceIssuers.nodes) || [];
  }

  findSelectedItem() {
    const { insuranceIssuer, insuranceIssuerId } = this.props;

    let selectedItem = insuranceIssuer;
    if (selectedItem == null && insuranceIssuerId) {
      selectedItem = this.getPartnerInsuranceIssuers().find(
        partnerIssuer => partnerIssuer.insuranceIssuer.id === insuranceIssuerId
      );
      selectedItem = selectedItem && selectedItem.insuranceIssuer;
    }
    return selectedItem;
  }

  renderMenu = comboboxProps => {
    if (this.props.partnerData.loading) {
      return [];
    }
    const { selectedItem, toLabel } = comboboxProps;
    let { inputValue } = comboboxProps;
    inputValue = normalizeInputValue(inputValue);
    if (inputValue === '' || normalizeInputValue(toLabel(selectedItem)) === inputValue) {
      return this.renderFeaturedIssuers(comboboxProps);
    }
    return this.renderSearchResults({ ...comboboxProps, inputValue });
  };

  renderFeaturedIssuers(comboboxProps) {
    const partnerIssuers = this.getPartnerInsuranceIssuers();
    const featuredIssuers = partnerIssuers
      .filter(partnerIssuer => partnerIssuer.isFeatured)
      .map(partnerIssuer => partnerIssuer.insuranceIssuer);
    const allIssuers = partnerIssuers
      .filter(partnerIssuer => !partnerIssuer.isFeatured)
      .map(partnerIssuer => partnerIssuer.insuranceIssuer);
    return (
      <SuggestedInsuranceIssuersMenu
        emptyLabel={this.props.emptyLabel}
        featuredInsuranceIssuers={featuredIssuers}
        allInsuranceIssuers={allIssuers}
        comboboxProps={comboboxProps}
      />
    );
  }

  renderSearchResults(comboboxProps) {
    let partnerIssuers = this.getPartnerInsuranceIssuers();
    const { inputValue, toLabel } = comboboxProps;
    partnerIssuers = partnerIssuers.filter(
      ({ insuranceIssuer }) =>
        normalizeInputValue(toLabel(insuranceIssuer)).indexOf(inputValue) !== -1
    );
    return partnerIssuers.map(({ insuranceIssuer }, index) => (
      <InsuranceIssuerSelectOption
        key={insuranceIssuer.id}
        item={insuranceIssuer}
        index={index}
        {...comboboxProps}
      />
    ));
  }

  render() {
    return (
      <Combobox
        loading={this.props.partnerData.loading}
        selectedItem={this.findSelectedItem()}
        {...this.props}
      >
        {this.renderMenu}
      </Combobox>
    );
  }
}
